import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AuthService from './api/msalAuthService'

import './navigation'

import VueGtm from '@gtm-support/vue2-gtm'

Vue.config.productionTip = process.env.NODE_ENV !== 'production' ? false : true

if (process.env.NODE_ENV == 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-5XCVR7P',
    vueRouter: router,
  })
}

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    AuthService.signIn()
  },
}).$mount('#app')
