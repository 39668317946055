<template>
  <transition name="fade" mode="out-in">
    <div class="overlay" v-if="showForm">
      <form class="form-body p-lg-6 p-sm-4 p-2" @submit="processForm($event)">
        <h2 class="h3 text-center">Let’s keep in touch!</h2>
        <p class="mb-4">
          We’d love to send you exclusive offers, discounts, rewards, loyalty
          promotions and the latest info from time to time and only when it will
          specifically benefit you. You can of course change your mind at any
          time.
        </p>
        <div class="mb-2">
          <input
            required
            v-model="selectedMarketingOption"
            type="radio"
            name="marketing-communications"
            id="yes"
            value="yes"
          />
          <label for="yes"
            >Yes please, I’d like to hear about offers, discounts, rewards and
            promotions.</label
          >
        </div>

        <div class="mb-6">
          <input
            required
            v-model="selectedMarketingOption"
            type="radio"
            name="marketing-communications"
            id="no"
            value="no"
          />
          <label for="no"
            >No thanks, I don’t want to hear about offers, discounts, rewards
            and promotions.</label
          >
        </div>
        <div class="text-center">
          <button type="submit" class="bd-btn bd-btn-one">Confirm</button>
        </div>
      </form>
    </div>
  </transition>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'UpdateMarketingCommunications',

    data() {
      return {
        showForm: false,
        selectedMarketingOption: null,
      }
    },

    methods: {
      ...mapActions('power', ['updatePowerMarketingCommunications']),
      ...mapActions('waste', ['updateWasteMarketingCommunications']),

      async processForm(event) {
        event.preventDefault()

        if (this.selectedMarketingOption === 'yes') {
          if (this.isElectricityActive || this.isGasActive) {
            const ids = this.currentPowerMarketingOptionsIds.toString()
            await this.updatePowerMarketingCommunications(ids)
          }

          if (this.isWasteActive) {
            await this.updateWasteMarketingCommunications(true)
          }

          this.showForm = false
        }

        if (this.selectedMarketingOption === 'no') {
          if (this.isElectricityActive || this.isGasActive) {
            await this.updatePowerMarketingCommunications('')
          }

          if (this.isWasteActive) {
            await this.updateWasteMarketingCommunications(false)
          }

          this.showForm = false
        }
      },
    },

    computed: {
      ...mapGetters('linkAccount', [
        'isElectricityActive',
        'isGasActive',
        'isWasteActive',
      ]),

      ...mapGetters('power', [
        'marketingOptions',
        'askToMarketingOptionsUpdate',
      ]),

      currentPowerMarketingOptionsIds() {
        if (this.marketingOptions) {
          const ids = Object.values(this.marketingOptions).map(obj => {
            return obj.id
          })

          return ids
        }

        return false
      },
    },

    watch: {
      askToMarketingOptionsUpdate: {
        handler(status) {
          if (status === true) {
            this.showForm = true
          }
        },
        deep: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .overlay {
    label {
      cursor: pointer;
    }
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #2929297e;
    position: fixed;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-body {
      max-width: 1068px;
      opacity: 1;
      height: auto;
      background: #f8fdf7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      z-index: 11;
    }
  }
</style>
