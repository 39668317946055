<template>
  <div class="container">
    <div class="row">
      <div
        v-if="isElectricityActive || isGasActive"
        class="col col-12 col-lg-6"
      >
        <div
          class="box-call-to-action rounded  shadow overflow-hidden  mb-3 bg-brand-secondary color-text"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <figure>
                <img
                  src="https://www.panda.ie/wp-content/uploads/2021/04/panda-home-skips-01a-364x364.jpg"
                  loading="lazy"
                  alt="panda-home-skips"
                  width="364"
                  height="364"
                  srcset="
                    https://www.panda.ie/wp-content/uploads/2021/04/panda-home-skips-01a-504x504.jpg 504w,
                    https://www.panda.ie/wp-content/uploads/2021/04/panda-home-skips-01a-364x364.jpg 364w,
                    https://www.panda.ie/wp-content/uploads/2021/04/panda-home-skips-01a-298x298.jpg 298w
                  "
                  sizes="(min-width: 1920px) 504px, (min-width: 992px) 364px, 298px"
                  class="w-100"
                />
              </figure>
            </div>
            <div
              class="col-12 col-md-6  d-flex flex-column align-items-start justify-content-center"
            >
              <div class="py-3 px-2 ps-lg-0">
                <h3 class="h4 mb-0 fadeIn">Skip hire</h3>
                <p class="fadeIn">
                  With great deals for skip hire and bag collection, we’ve got
                  you covered.
                </p>
                <a
                  target="_blank"
                  class="bd-btn bd-btn-three fadeInUp transition-delay-25"
                  href="https://pandagreenwaste-portal.amcsplatform.com/orders?iframe=a1skips&iframe=a1skips&_ga=2.63416807.91460524.1633964891-786311006.1633093347"
                  >Book Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isWasteActive && (isElectricityActive || isGasActive)"
        class="col col-12 col-lg-6"
      >
        <div
          class="box-call-to-action rounded  shadow overflow-hidden  mb-3 bg-linear-gradient-green color-white"
        >
          <div class="row">
            <div class="col-12 col-md-6">
              <figure class="">
                <img
                  src="https://www.panda.ie/wp-content/uploads/2021/04/panda-home-waste-card-364x364.jpg"
                  loading="lazy"
                  alt="panda-skip-bag"
                  width="364"
                  height="364"
                  srcset="
                    https://www.panda.ie/wp-content/uploads/2021/04/panda-home-waste-card-504x504.jpg 504w,
                    https://www.panda.ie/wp-content/uploads/2021/04/panda-home-waste-card-364x364.jpg 364w,
                    https://www.panda.ie/wp-content/uploads/2021/04/panda-home-waste-card-298x298.jpg 298w
                  "
                  sizes="(min-width: 1920px) 504px, (min-width: 992px) 364px, 298px"
                  class="w-100"
                />
              </figure>
            </div>
            <div
              class="col-12 col-md-6  d-flex flex-column align-items-start justify-content-center"
            >
              <div class="py-3 px-2 ps-lg-0">
                <h3 class="h4 mb-0 fadeIn">Add Waste?</h3>
                <p class="fadeIn">
                  Bringing you weekly refuse collections and affordable
                  services.
                </p>
                <a
                  target="_blank"
                  class="bd-btn bd-btn-three fadeInUp transition-delay-25"
                  href="https://www.panda.ie/panda-home/sign-up/#/"
                  >Switch Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'ConditionalCTAs',
    computed: {
      ...mapGetters('linkAccount', [
        'isElectricityActive',
        'isGasActive',
        'isWasteActive',
      ]),
    },
  }
</script>

<style></style>
