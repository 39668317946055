<template>
  <div>
    <GasOverview />
    <AccountSection sectionTitle="Transaction history">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <GasBillingHistory title="Payments" type="payments" />
        </div>
      </div>
    </AccountSection>

    <AccountSection sectionTitle="">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <GasBillingHistory title="Invoices" type="invoices" />
        </div>
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import GasOverview from '@/components/gas/Overview.vue'
  import GasBillingHistory from '@/components/gas/BillingHistory.vue'
  import GasMeterReadingHistory from '@/components/gas/MeterReadingHistory.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'

  export default {
    name: 'GasTransactionHistory',
    components: {
      AccountSection,
      GasOverview,
      GasBillingHistory,
      GasMeterReadingHistory,
    },
  }
</script>

<style></style>
