<template>
  <div>
    <WasteOverview />
    <AccountSection sectionTitle="Collection history">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <WasteMeterReadingHistory
            TableTitle="Collection history"
            buttonText=""
            TableHeadingOne="Date"
            TableHeadingTwo="Type"
            TableHeadingThree="Weight"
          />
        </div>
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import WasteOverview from '@/components/waste/Overview.vue'
  import WasteBillingHistory from '@/components/waste/BillingHistory.vue'

  import AccountSection from '@/components/layouts/AccountSection.vue'

  export default {
    name: 'Electricity',
    components: {
      AccountSection,
      WasteOverview,
      WasteBillingHistory,
    },
  }
</script>

<style></style>
