<template>
  <div>
    <div
      class="bg-grey rounded p-4 p-lg-4 pb-lg-4 mb-5 position-relative"
      ref="paymentForm"
    >
      <TheSmallLoader :showLoader="isLoading" />
      <ApiMessage :messageText="theMessage" :show="showMessage" />
      <div class="row">
        <div class="col-12 col-md-8">
          <h6 class="mb-0">Latest bill</h6>
          <p class="h3 m-0" v-if="latestBill">€{{ latestBill.totalAmount }}</p>
          <p class="mt-0 mb-2 mb-md-0" v-if="latestBill">
            Issued on: <strong>{{ latestBill.formattedDate }} </strong>
          </p>
        </div>
        <div
          class="d-flex flex-column col-12 col-md-4"
          v-bind:style="{ opacity: columnOpacity }"
        >
          <button
            @click="showPaymentForm"
            class="bd-btn bd-btn-one mb-2 text-center mx-0 mx-md-1"
          >
            Pay now
          </button>
          <button
            v-if="latestBill"
            @click="openInvoice(latestBill.number, $event)"
            class="bd-btn bd-btn-one mb-2 text-center mx-0 mx-sm-1 mx-md-1"
          >
            Download
          </button>
        </div>
      </div>
      <div v-show="showPaymentField" class="row">
        <strong>Amount to pay *</strong>
      </div>
      <div v-show="showPaymentField" class="row mt-2 mb-2">
        <div class="col-12 col-md-8">
          <input
            v-model="paymnetAmmount"
            @keypress="onlyNumber"
            type="text"
            name=""
            id=""
            class="w-100"
            placeholder="€00.00"
          />
        </div>
        <div
          class="d-flex flex-column col-12 col-md-4 align-items-center justify-content-center"
        >
          <button
            @click="processForm"
            class="bd-btn bd-btn-one text-center w-100 justify-content-center"
          >
            Pay now
          </button>
        </div>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
  import TheSmallLoader from '@/components/TheSmallLoader.vue'
  import ApiMessage from '@/components/ApiMessage.vue'

  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'LatestBiling',
    components: {
      ApiMessage,
      TheSmallLoader,
    },

    data() {
      return {
        columnOpacity: 1,
        showPaymentField: false,
        paymnetAmmount: null,
        showMessage: false,
        isLoading: false,
        theMessage: '',
      }
    },

    computed: {
      ...mapGetters('electricity', ['latestBill', 'payByLink']),
    },

    methods: {
      ...mapActions('power', ['fetchInvoiceData']),
      ...mapActions('electricity', ['makeElectricityPayment']),

      saveAs(uri, filename) {
        const link = document.createElement('a')
        if (typeof link.download === 'string') {
          link.href = uri
          link.download = filename
          //Firefox requires the link to be in the body
          document.body.appendChild(link)
          //simulate click
          link.click()
          //remove the link when done
          document.body.removeChild(link)
        } else {
          window.open(uri)
        }
      },

      async openInvoice(invoiceNumber, event) {
        event.preventDefault()
        const invoceData = await this.fetchInvoiceData(invoiceNumber)
        const content = 'data:application/pdf;base64, ' + invoceData.invoiceFile
        this.saveAs(content, invoceData.invoiceFileName)
      },

      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          $event.preventDefault()
        }
      },

      showPaymentForm() {
        this.columnOpacity = 0
        this.showPaymentField = true
      },

      async processForm() {
        if (this.paymnetAmmount && this.paymnetAmmount !== 0) {
          this.isLoading = true

          await this.makeElectricityPayment(this.paymnetAmmount)

          if (this.payByLink) {
            this.isLoading = false
            this.showMessage = true
            this.theMessage = 'Redirecting to Global Payments'
            window.location.replace(this.payByLink)
          } else {
            this.isLoading = false
            this.showMessage = true
            this.theMessage = 'Something went wrong, please contact support.'
          }
        }
      },

      scrollToForm() {
        this.showPaymentForm()
        this.$refs['paymentForm'].scrollIntoView({ behavior: 'smooth' })
      },
    },

    watch: {
      $route(to, from) {
        if (to.hash === '#makepayment') {
          this.scrollToForm()
        }
      },
    },

    mounted() {
      if (this.$route.hash === '#makepayment') {
        this.scrollToForm()
      }
    },
  }
</script>

<style lang="scss" scoped></style>
