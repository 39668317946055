<template>
  <div>
    <div class="bg-grey rounded p-4 p-lg-4 pb-lg-4">
      <h4 class="">What can I put in my recycling bin?</h4>
      <p class="pb-4">
        Not sure what goes in which bin? Get a quick and simple overview to help
        make sure you put your waste in the right place.
      </p>
      <a
        class="color-aquamarine hover-color-aquamarine text-decoration-none  heading-font-family d-inline-flex align-items-center"
        href="https://www.panda.ie/faq-category/recycling-and-composting-tips/#accordion"
        target="_blank"
        >More Info</a
      >

      <slot />
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped></style>
