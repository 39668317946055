<template>
  <div>
    <WasteOverview />
    <AccountSection sectionTitle="Next Collection">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <NextCollections />
        </div>
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import WasteOverview from '@/components/waste/Overview.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'
  import NextCollections from '@/components/waste/NextCollections.vue'

  export default {
    name: 'WasteNextCollectionsView',

    components: {
      AccountSection,
      WasteOverview,
      NextCollections,
    },

    methods: {
      ...mapActions('waste', ['fetchNextCollections']),
    },

    computed: {
      ...mapGetters('waste', ['nextCollections']),
    },

    mounted() {
      if (!this.nextCollections.length) {
        this.fetchNextCollections()
      }
    },
  }
</script>

<style></style>
