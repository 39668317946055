<script>
  import { Line } from 'vue-chartjs'
  import { mapGetters } from 'vuex'

  export default {
    extends: Line,
    data() {
      return {
        labels: [],
        datasets: [],
      }
    },
    methods: {
      initChart() {
        if (this.dailyReadsShortDate) {
          this.renderChart(
            {
              labels: this.dailyReadsShortDate,
              datasets: [
                {
                  label: 'Usage KW',
                  data: this.dailyReadsUsage,
                  backgroundColor: 'transparent',
                  borderColor: 'rgba(1, 116, 188, 0.50)',
                  pointBackgroundColor: 'rgba(171, 71, 188, 1)',
                },
              ],
            },
            {
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: false,
                text: 'Ussage',
              },
            }
          )
        }
      },
    },
    computed: {
      ...mapGetters('electricity', ['dailyReadsShortDate', 'dailyReadsUsage']),
    },

    watch: {
      dailyReadsUsage: {
        handler(status) {
          this.initChart()
        },
        deep: true,
      },
    },

    mounted() {
      this.initChart()
    },
  }
</script>
