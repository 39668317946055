<template>
  <div>
    <TheLoader :showLoader="isLoading" />
    <PageOverview sectionTitle="Your Panda accounts" />
    <ConditionalCtas />
    <FooterCtas />
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  import TheLoader from '@/components/TheLoader.vue'
  import PageOverview from '@/components/LandingOverview.vue'
  import ConditionalCtas from '@/components/ConditionalCtas.vue'
  import FooterCtas from '@/components/FooterCtas.vue'

  export default {
    components: {
      TheLoader,
      PageOverview,
      ConditionalCtas,
      FooterCtas,
    },
    data() {
      return {
        isLoading: false,
        prevRoute: null,
      }
    },
    methods: {
      ...mapMutations('linkAccount', [
        'DELETE_EMAIL_CONFORMATION_DETAILS',
        'DELETE_TEXT_CONFORMATION_DETAILS',
      ]),
    },
    computed: {
      ...mapGetters('power', ['accountDetails', 'fullName', 'address']),

      ...mapGetters('linkAccount', [
        'isElectricityActive',
        'isGasActive',
        'isWasteActive',
        'emailIsValidated',
        'textIsValidated',
      ]),
    },

    mounted() {
      if (this.emailIsValidated) {
        this.DELETE_EMAIL_CONFORMATION_DETAILS()
      }
      if (this.textIsValidated) {
        this.DELETE_TEXT_CONFORMATION_DETAILS()
      }
    },
  }
</script>
