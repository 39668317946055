import axios from 'axios'

const state = {
  footer: sessionStorage.getItem('app_footer') || false,
}

const getters = {}

const actions = {
  async fetchFooter({ commit }) {
    try {
      const response = await axios.get(
        'https://www.panda.ie/wp-json/wp/v2/footer_html'
      )
      const html = response.data
      sessionStorage.setItem('app_footer', html)
    } catch (error) {}
  },

  async getFooter({ commit, state, dispatch }) {
    if (!state.footer) {
      console.log(!state.footer)
      await dispatch('fetchFooter')
      return sessionStorage.getItem('app_footer')
    } else {
      return sessionStorage.getItem('app_footer')
    }
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
