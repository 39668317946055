<template>
  <div>
    <div class="row">
      <div class="col-6 ">
        <h6 class="mb-2">Gas</h6>
        <p class="m-0 mb-1">
          <strong> GPRN: {{ gasAccountInfo.meterPointNumber }} </strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'MeterInfo',
    computed: {
      ...mapGetters('gas', ['gasAccountInfo']),
    },
  }
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 50px;
  }
</style>
