<template>
  <div class="overview bg-palegreen position-relative">
    <HeroBg class="position-absolute top-0 end-0 z1" />

    <div class="h-100 bg-palegreen position-relative min-vh-100">
      <div class="container min-vh-100">
        <div class="row justify-content-center flex-column align-items-center">
          <div
            class="col-xs-12 col-sm-12 col-md-10 col-lg-7 col-xl-6 mt-6 mt-md-8 z1"
          >
            <div
              class="bg-white shadow rounded p-3 p-sm-4 p-md-5 p-lg-6 position-relative"
            >
              <img
                src="@/assets/images/panda-logo.png"
                alt="panda logo"
                class="d-block mb-3"
              />
              <slot />
            </div>
          </div>
          <div
            class="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-6 mt-auto pt-4 z1"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HeroBg from '@/assets/images/panda-hero-bgwhite.svg'
  import PandaLogo from '@/assets/images/panda-logo.svg'

  export default {
    components: {
      HeroBg,
      PandaLogo,
    },
  }
</script>

<style lang="scss">
  .min-vh-100 {
    min-height: 100vh;
  }
</style>
