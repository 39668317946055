<template>
  <div class="overview bg-palegreen position-relative">
    <!-- <div class="hero-bg position-absolute top-0 end-0"></div> -->
    <div class="py-6 hero-bg">
      <div class="container z-2 position-relative">
        <div class="row">
          <div class="col-12 align-items-center">
            <h1 class="main-heading d-inline-flex me-4">
              {{ sectionTitle }}
            </h1>
            <div class="position-relative d-inline-block">
              <button
                @click="showDropdown = !showDropdown"
                class="bd-btn bd-btn-one d-inline-flex"
              >
                Switch account
                <span v-show="showDropdown">
                  <i class="fas fa-caret-up ms-1"></i>
                </span>
                <span v-show="!showDropdown">
                  <i class="fas fa-caret-down ms-1"></i>
                </span>
              </button>

              <div v-if="showDropdown" class="accounts-dropdown bg-white">
                <h6 class="mb-0">Switch account</h6>
                <ul class="list-unstyled">
                  <li
                    v-for="(account, index) in allAccounts"
                    :key="index"
                    :class="account.isDefault ? 'active' : 'inactive'"
                  >
                    <button v-if="account.isDefault">
                      Account #{{ account.accountNumber }} <span>Active</span>
                    </button>
                    <button
                      v-else
                      toggle
                      @click="
                        toggleDefaultAccount({
                          account: account.accountNumber,
                          type: account.accountType,
                        })
                      "
                    >
                      Account #{{ account.accountNumber }}
                      <span>Switch</span>
                    </button>
                  </li>

                  <li class="add-existing-account">
                    <router-link tag="div" :to="{ name: 'link-account' }">
                      <button>
                        <span><i class="fas fa-user-plus"></i></span> Add
                        existing account
                      </button>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row z-2 mt-2">
          <NoAccountFound />
          <div
            v-if="isElectricityActive"
            class="col-12 col-sm-12 col-md-6 col-lg-4 d-flex"
          >
            <router-link
              tag="div"
              :to="{ name: 'electricity' }"
              class="card shadow  color-white p-3 rounded mb-4 bg-radial-gradient-blue-green"
            >
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <strong><h6 class="mb-1">Balance</h6></strong>
                    </div>
                    <div class="col-6 text-right">View Account</div>
                  </div>

                  <p class="h3 m-0 mb-5 p-0">€{{ electricityBalance }}</p>
                </div>
              </div>
              <div class="row mb-1">
                <strong><h6 class="mb-0">Home Electricity</h6></strong>
              </div>
              <div class="row">
                <p class="mb-1">
                  {{ address }}
                </p>
                <p>
                  Account Number:
                  <strong>{{ electricityAccountNumber }}</strong>
                </p>
              </div>
            </router-link>
          </div>
          <div
            v-if="isGasActive"
            class="col-12 col-sm-12 col-md-6 col-lg-4 d-flex"
          >
            <router-link
              tag="div"
              :to="{ name: 'gas' }"
              class="card shadow bg-radial-gradient-blue color-white p-3 rounded mb-4"
            >
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <strong><h6 class="mb-1">Balance</h6></strong>
                    </div>
                    <div class="col-6 text-right">View Account</div>
                  </div>

                  <p class="h3 m-0 mb-5 p-0">€{{ gasBalance }}</p>
                </div>
              </div>
              <div class="row mb-1">
                <strong><h6 class="mb-0">Home Gas</h6></strong>
              </div>
              <div class="row">
                <p class="mb-1">
                  {{ address }}
                </p>
                <p>
                  Account Number: <strong>{{ gasAccountNumber }}</strong>
                </p>
              </div>
            </router-link>
          </div>
          <div
            v-if="isWasteActive"
            class="col-12 col-sm-12 col-md-6 col-lg-4 d-flex"
          >
            <router-link
              tag="div"
              :to="{ name: 'waste' }"
              class="card shadow bg-radial-gradient-green color-white p-3 rounded mb-4"
            >
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-6">
                      <strong><h6 class="mb-1">Balance</h6></strong>
                    </div>
                    <div class="col-6 text-right">View Account</div>
                  </div>

                  <p class="h3 m-0 mb-5 p-0">€{{ wasteBalance }}</p>
                </div>
              </div>
              <div class="row mb-1">
                <strong><h6 class="mb-0">Home Recycling</h6></strong>
              </div>
              <div class="row">
                <p class="mb-1">{{ wasteAccountAddress }}</p>
                <p>
                  Account Number: <strong>{{ wasteAccountNumber }}</strong>
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <router-link :to="{ name: 'link-account' }">
              <button class="add-account-secondary">
                <span><i class="fas fa-user-plus"></i></span> Add existing
                account
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import HeroBg from '@/assets/images/panda-bg-paw.svg'
  import NoAccountFound from '@/components/NoAccountFound.vue'
  export default {
    name: 'overview',
    components: {
      HeroBg,
      NoAccountFound,
    },

    props: {
      sectionTitle: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showDropdown: false,
      }
    },

    methods: {
      toggleDropdown() {},
      ...mapActions('linkAccount', ['toggleDefaultAccount']),
    },
    computed: {
      ...mapGetters('linkAccount', [
        'isElectricityActive',
        'isGasActive',
        'isWasteActive',
        'allAccounts',
      ]),
      ...mapGetters('power', ['address']),
      ...mapGetters('electricity', [
        'electricityBalance',
        'electricityAccountNumber',
      ]),
      ...mapGetters('gas', ['gasBalance', 'gasAccountNumber']),
      ...mapGetters('waste', [
        'wasteAccountNumber',
        'wasteAccountAddress',
        'wasteBalance',
      ]),
    },
  }
</script>

<style lang="scss">
  .card {
    cursor: pointer;
  }
  .bd-btn-one {
    padding: 1rem;
  }
  .main-heading {
    font-size: 36px;
    margin-bottom: 50px !important;
    vertical-align: sub;
  }

  .accounts-dropdown {
    margin-top: 8px;
    width: 346px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1001;
    h6 {
      padding: 24px 24px 16px 24px;
      margin-bottom: 0;
    }
    button {
      display: block;
      width: 100%;
      appearance: none;
      // background: red;
      border: none;
      padding: 16px 24px;
      text-align: left;

      position: relative;
      span {
        position: absolute;
        right: 24px;
      }
    }

    .active {
      button {
        background: radial-gradient(
          91.18% 149.4% at 100% 86.35%,
          #41bb2e 0%,
          #01a48b 100%
        );

        color: white;
        font-weight: 500;
        // background-color: ;
      }
    }
    .inactive {
      button {
        font-weight: 400;
        border-bottom: 1px solid #b8b8b8;
        cursor: pointer;
        background-color: #fff;
        &:hover {
          background-color: #f5f5f5;
        }

        span {
          color: #01a48b;
          text-decoration: underline;
        }
      }
    }
    .add-existing-account {
      // padding-top: 16px;
      // padding-bottom: 16px;
      padding: 16px 0;

      background-color: #fff;
      button {
        display: block;
        // max-width: 195px;
        width: auto;
        height: 37px;
        margin-left: auto;
        margin-right: auto;
        // flex-direction: row;
        // align-items: center;
        padding: 8px 16px;
        // gap: 8px;
        color: #515151;
        border: 1px solid #515151;
        border-radius: 8px;
        background: transparent;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
        span {
          margin-right: 8px;
          // left: 16px;
          font-size: 16px;
          position: unset;
        }
      }
    }
  }

  .add-account-secondary {
    appearance: none;
    cursor: pointer;
    border: none;
    background: transparent;
  }
</style>
