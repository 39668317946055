<template>
  <div
    v-if="showLoader"
    class="w-100 d-flex align-items-center d-flex justify-content-center"
    id="small-loader"
  >
    <i class="far fa-spinner-third fa-spin fa-3x color-brand-primary"></i>
  </div>
</template>

<script>
  export default {
    name: 'LoaderSmall',
    props: {
      showLoader: {
        type: Boolean,
        required: true,
      },
    },
  }
</script>

<style scoped lang="scss" scoped>
  #small-loader {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5b5;
  }
</style>
