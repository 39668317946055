<template>
  <div>
    <ElectricityOverview />
    <AccountSection sectionTitle="Transaction history">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <ElectricityBillingHistory title="Payments" type="payments" />
        </div>
      </div>
    </AccountSection>

    <AccountSection>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <ElectricityBillingHistory title="Invoices" type="invoices" />
        </div>
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import ElectricityOverview from '@/components/electricity/Overview.vue'
  import ElectricityBillingHistory from '@/components/electricity/BillingHistory.vue'
  import ElectricityMeterReadingHistory from '@/components/electricity/MeterReadingHistory.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'

  export default {
    name: 'ElectricityTransactionHistory',
    components: {
      AccountSection,
      ElectricityOverview,
      ElectricityBillingHistory,
      ElectricityMeterReadingHistory,
    },
  }
</script>

<style></style>
