<script>
  import { Line } from 'vue-chartjs'
  import { mapGetters } from 'vuex'
  export default {
    extends: Line,

    computed: {
      ...mapGetters('gas', ['meterUsageDates', 'meterUsageConsumption']),
    },

    methods: {
      initChart() {
        this.renderChart(
          {
            labels: this.meterUsageDates,
            datasets: [
              {
                label: 'Consumption Kwh',
                data: this.meterUsageConsumption,
                backgroundColor: 'transparent',
                borderColor: 'rgba(1, 116, 188, 0.50)',
                pointBackgroundColor: 'rgba(171, 71, 188, 1)',
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
              text: 'Ussage',
            },
          }
        )
      },
    },

    mounted() {
      this.initChart()
    },

    watch: {
      meterUsageDates: {
        handler(status) {
          this.initChart()
        },
        deep: true,
      },
    },
  }
</script>
