<template>
  <header id="masthead" class="site-header heading-font-family text-center">
    <div id="top-bar" class="bg-offBlack w-100 color-white text">
      <div class="container">
        <div class="row ">
          <div class="col col-12 d-flex justify-content-between px-0 px-md-2">
            <ul
              id="sector-menu"
              class="d-flex list-unstyled font-size-small flex-grow-1 flex-md-grow-0"
            >
              <li
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-72"
              >
                <a
                  class="p-1 py-2 p-sm-2 p-md-3 d-inline-block bg-grey-90 color-white  visited-color-white hover-color-color-text hover-bg-white w-100 text-decoration-none"
                  href="https://www.panda.ie/"
                  >Panda Home</a
                >
              </li>
              <li
                class=" menu-item menu-item-type-post_type menu-item-object-page"
              >
                <a
                  class="p-1 py-2 p-sm-2 p-md-3 d-inline-block bg-grey-90 color-white  visited-color-white hover-color-color-text hover-bg-white w-100 text-decoration-none"
                  href="https://www.panda.ie/business/"
                  >Panda Business</a
                >
              </li>
            </ul>
            <ul
              id="top-bar-links"
              class="list-unstyled d-flex align-items-center justify-content-center flex-grow-1 flex-md-grow-0"
            >
              <li class="d-none d-md-inline">
                <a
                  href="https://www.panda.ie/"
                  class="color-white button-unstyled intercom-launch"
                  aria-label="Launch live chat"
                >
                  Live Chat
                </a>
              </li>
              <li class="d-none d-md-inline">
                <a
                  class="color-white visited-color-white"
                  target="_blank"
                  href="https://www.panda.ie/"
                >
                  My Panda</a
                >
              </li>
              <li class="d-md-inline">
                <button
                  @click="logOut"
                  class="color-white button-unstyled intercom-launch"
                  aria-label="Launch live chat"
                >
                  Sign Out
                </button>
              </li>

              <!-- TODO: Update aria label on search button when opening and closing (if it ever goes in) -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="main_bar" class="py-2 py-lg-0">
      <div class="container">
        <div class="row">
          <div
            class="col col-12 d-flex justify-content-between align-items-end"
          >
            <div class="site-branding my-lg-3">
              <a href="https://www.panda.ie" class="custom-logo-link" rel="home"
                ><img
                  src="https://www.panda.ie/wp-content/themes/bigrig/assets/images/panda-logo.svg"
                  class="custom-logo"
                  alt="Panda"
                  sizes="100vw"
              /></a>
            </div>
            <!-- .site-branding -->

            <nav
              id="site-navigation"
              class="main-navigation nav--toggle-sub nav--toggle-small"
              aria-label="Main menu"
            >
              <button
                class="menu-toggle d-lg-none button-unstyled position-relative"
                aria-label="Open menu"
                aria-controls="primary-menu"
                aria-expanded="false"
              >
                <svg
                  class="svg-inline--fa fa-bars fa-w-14 fa-2x"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="bars"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
                  ></path></svg
                ><!-- <i class="far fa-bars fa-2x"></i> Font Awesome fontawesome.com -->
                <svg
                  class="svg-inline--fa fa-times fa-w-10 fa-2x"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="times"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                  ></path></svg
                ><!-- <i class="far fa-times fa-2x"></i> Font Awesome fontawesome.com -->
              </button>

              <div
                class="primary-menu-container bg-white z5 text-start text-lg-center"
              >
                <ul
                  id="primary-menu"
                  class="menu primary-menu d-flex flex-column flex-lg-row list-unstyled "
                >
                  <li
                    id=" menu-item-185"
                    class=" menu-item menu-item-type-post_type menu-item-object-page"
                  >
                    <router-link
                      :to="{ name: 'home' }"
                      class="d-inline-block m-2 mb-lg-3 hover-color-color-text visited-color-color-text text-decoration-none position-relative"
                      >My Accounts</router-link
                    >
                  </li>

                  <li
                    id=" menu-item-187"
                    class=" menu-item menu-item-type-post_type menu-item-object-page"
                  >
                    <a
                      class="d-inline-block m-2 mb-lg-3 hover-color-color-text visited-color-color-text text-decoration-none position-relative"
                      href="https://www.panda.ie/help/"
                      >Support</a
                    >
                  </li>
                </ul>
                <button
                  class="button-unstyled p-2 mt-auto d-md-none intercom-launch"
                  aria-label="Launch live chat"
                >
                  <svg
                    class="svg-inline--fa fa-comment-lines fa-w-16 fa-lg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="comment-lines"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zm32 264c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm96-96c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16z"
                    ></path></svg
                  ><!-- <i class="fas fa-comment-lines fa-lg"></i> Font Awesome fontawesome.com -->
                </button>

                <!-- TODO: Update aria label on search button when opening and closing -->
              </div>
            </nav>
            <!-- #site-navigation -->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import AuthService from '@/api/msalAuthService'
  export default {
    name: 'AppHeader',
    methods: {
      logOut() {
        AuthService.signOut()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-logo {
    max-width: 132px;
  }
</style>
