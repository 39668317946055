<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6" v-if="TableTitle">
            <h6 class="">{{ TableTitle }}</h6>
          </div>
          <div class="col-6" v-if="!showFull">
            <strong>
              <router-link
                class="color-grey-700 text-decoration-none text-right d-block"
                :to="{ name: 'electricity-reading-history' }"
                >View usage history
              </router-link>
            </strong>
          </div>
        </div>
        <table class="w-100 text-left">
          <thead class="color-white bg-linear-gradient-green">
            <tr>
              <th scope="col" class="px-3 p-2">{{ TableHeadingOne }}</th>
              <th scope="col" class="px-3 p-2">{{ TableHeadingTwo }}</th>
              <th scope="col" class="px-3 p-2">{{ TableHeadingThree }}</th>
            </tr>
          </thead>
          <tbody v-for="reading in getMeterReads" :key="reading.date">
            <tr class="bg-grey">
              <td class="px-3 py-2">{{ reading.formattedDate }}</td>
              <td class="px-3 py-2">{{ reading.readingValue }}</td>
              <td class="px-3 py-2">{{ reading.readTypeDesc }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'MeterReadingHistory',
    props: {
      showFull: {
        type: Boolean,
      },

      TableTitle: {
        type: String,
      },

      buttonText: {
        type: String,
      },

      TableHeadingOne: {
        type: String,
      },
      TableHeadingTwo: {
        type: String,
      },
      TableHeadingThree: {
        type: String,
      },
      fullList: {
        type: Boolean,
      },
    },

    data() {
      return {
        reads: [],
      }
    },

    computed: {
      ...mapGetters('electricity', ['meterReadings']),

      getMeterReads() {
        if (this.meterReadings.length > 5 && !this.$props.showFull) {
          return this.meterReadings.slice(0, 5)
        } else {
          return this.meterReadings
        }
      },
    },
  }
</script>

<style lang="scss" scoped></style>
