<template>
  <div id="app">
    <TheLoader :showLoader="isLoading" />
    <div v-if="!isLoading">
      <UpdateMarketingCommunications />
    </div>

    <AppHeader />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <AppFooter />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import TheLoader from '@/components/TheLoader.vue'
  import AuthService from '@/api/msalAuthService'
  import AppFooter from '@/components/AppFooter.vue'
  import AppHeader from '@/components/AppHeader.vue'

  import UpdateMarketingCommunications from '@/components/UpdateMarketingCommunications.vue'

  export default {
    name: 'App',
    components: {
      AppHeader,
      AppFooter,
      TheLoader,
      UpdateMarketingCommunications,
    },

    data() {
      return {
        isLoading: true,
        showMarketingForm: false,
      }
    },
    methods: {
      ...mapActions('linkAccount', [
        'linkInitialAccounts',
        'fetchLinkedAccounts',
      ]),
      ...mapActions('power', ['fetchCustomerData', 'fetchReadings']),
      ...mapActions('waste', ['fetchAccount']),

      ...mapActions('electricity', ['fetchSmartReading']),
    },

    created() {
      setTimeout(() => {
        if (AuthService.isAuthenticated()) {
          this.linkInitialAccounts().then(() => {
            this.fetchLinkedAccounts().then(() => {
              this.fetchCustomerData().then(() => {
                this.fetchSmartReading()
                this.fetchReadings()
                this.fetchAccount().then(() => {
                  this.isLoading = false
                  this.showMarketingForm = true
                })
              })
            })
          })
        }
      }, 2000)
    },
    mounted() {
      document.title = 'MyPanda Account'
    },
  }
</script>

<style lang="scss">
  @import '@/assets/styles/main.scss';

  #app {
    min-height: 100vh;
  }

  .hero-bg {
    background-position: top right;
    background-image: url('/static-assets/panda-bg-paw.svg');
    background-size: 10rem;
    background-repeat: no-repeat;
  }

  @media (min-width: 62rem) {
    .hero-bg {
      background-size: 15rem;
    }
  }

  @media (min-width: 75rem) {
    .hero-bg {
      background-size: 35%;
    }
  }
</style>
