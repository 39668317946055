<template>
  <div class="loader-overlay" v-if="showLoader">
    <div class="loader loader1">
      <div>
        <div>
          <div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      showLoader: {
        type: Boolean,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  $grey: rgba(0, 0, 0, 0.5);
  $blue: rgba(0, 0, 255, 0.5);

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate2 {
    0% {
      transform: rotate(0deg);
      border-top-color: $grey;
    }
    50% {
      transform: rotate(180deg);
      border-top-color: $blue;
    }
    100% {
      transform: rotate(360deg);
      border-top-color: $grey;
    }
  }

  @mixin loaderDivMixin {
    border-radius: 50%;
    padding: 8px;
    border: 2px solid transparent;
    animation: rotate linear 3.5s infinite;
  }
  .loader-overlay {
    background-color: #fff;
    // background: linear-gradient(90deg, #00ac65 0%, #00c0ae 100%);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .loader {
    position: relative;
    margin: 75px auto;
    // width: 150px;
    // height: 150px;
    display: block;
    overflow: hidden;

    div {
      height: 100%;
    }
  }

  /* loader 1 */
  .loader1,
  .loader1 div {
    @include loaderDivMixin;
    border-top-color: $grey;
    border-bottom-color: $blue;
  }

  /*loader 2  */
  .loader2,
  .loader2 div {
    @include loaderDivMixin;
    border-top-color: $blue;
    border-left-color: $grey;
    border-right-color: $grey;
  }

  /*loader 3  */
  .loader3,
  .loader3 div {
    @include loaderDivMixin;
    border-top-color: $grey;
    border-left-color: $blue;
    animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
    animation-duration: 3s;
  }

  /* loader 4 */
  .loader4,
  .loader4 div {
    @include loaderDivMixin;
    border-radius: 50%;
    padding: 4px;
    animation: rotate2 4s infinite linear;
  }

  div:hover {
    animation-play-state: paused;
  }

  .loader,
  .loader * {
    will-change: transform;
  }
</style>
