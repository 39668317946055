<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <h6 class="">Your next collections</h6>
              </div>
              <div class="col-6">
                <a
                  @click="downloadPDF($event)"
                  href="#"
                  class="color-grey-700 text-decoration-none text-right d-block mb-3"
                  >Download PDF</a
                >
              </div>
            </div>
          </div>
        </div>

        <table ref="collectionCalendar" class="w-100 text-left">
          <thead class="color-white bg-linear-gradient-green">
            <tr>
              <th scope="col" class="px-3 p-2">Date</th>
              <th scope="col" class="px-3 p-2">Day</th>
              <th scope="col" class="px-3 p-2">Type</th>
            </tr>
          </thead>
          <tbody v-for="(collection, index) in nextCollections" :key="index">
            <tr class="bg-grey">
              <td class="px-3 py-2">
                {{ collection.formattedDate }}
              </td>
              <td class="px-3 py-2">
                {{ collection.weekday }}
              </td>
              <td class="px-3 py-2">
                <i
                  :style="{ color: collection.binColourCustom }"
                  class="fas fa-circle"
                ></i
                ><span class="ms-2 d-inline-block"
                  >{{ collection.binType }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'NextCollections',

    methods: {
      ...mapActions('waste', ['fetchCollectionsCalendarUrl']),

      async downloadPDF(event) {
        event.preventDefault()
        if (!this.calendarUrl) {
          await this.fetchCollectionsCalendarUrl()
        }

        window.open(this.calendarUrl, '_blank')
      },
    },

    computed: {
      ...mapGetters('waste', ['nextCollections', 'calendarUrl']),
    },
  }
</script>

<style lang="scss" scoped></style>
