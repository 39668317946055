<template>
  <div class="col-12">
    <div v-if="pinIsValidated">
      <h1 class="h6">Account verified</h1>
      <p class="mb-4">Successfully linked Domestic account!</p>
      <router-link :to="{ name: 'home' }" class="bd-btn bd-btn-one"
        >View Your Accounts
      </router-link>
    </div>
    <div v-else>
      <TheSmallLoader :showLoader="isLoading" />
      <h1 class="h6">Account verification</h1>
      <p v-if="pinValidationError">
        <strong>{{ pinValidationError }}</strong>
      </p>
      <p v-else-if="isError">
        <strong>{{ errorMessage }}</strong>
      </p>
      <p>
        Please enter your account number
      </p>

      <TheCodeInput
        :loading="false"
        class="account-number"
        :fields="7"
        fieldWidth="45px"
        v-on:complete="accountNumberEntered"
      />
      <p>Please enter your 4 digit PIN</p>
      <TheCodeInput
        :loading="false"
        class="account-pin"
        :fields="4"
        fieldWidth="45px"
        v-on:complete="pinNumberEntered"
      />

      <div class="mt-4">
        <input
          @click="goBack"
          type="button"
          value="cancel"
          class="bd-btn bd-btn-two me-3"
        />
        <input
          @click="validatePin"
          type="button"
          value="Verify Account"
          class="bd-btn bd-btn-one"
        />
      </div>

      <div class="mt-4">
        <p>
          Need assistance? Contact our dedicated MyPanda portal support team at
          <a href="mailto:portalsupport@panda.ie">portalsupport@panda.ie</a> or
          visit our <a href="https://www.panda.ie/help/">FAQs</a> for more
          information.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import TheCodeInput from '@/components/TheCodeInput.vue'
  import TheSmallLoader from '@/components/TheSmallLoader.vue'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    components: {
      TheCodeInput,
      TheSmallLoader,
    },

    data() {
      return {
        accountNumber: '',
        pin: '',
        isAccountNumberValid: false,
        isError: false,
        errorMessage: `Please check if your account is not already linked, otherwise contact support.`,
        isLoading: false,
      }
    },

    methods: {
      ...mapActions('linkAccount', ['verifyPin']),

      goBack() {
        this.$router.back()
      },

      accountNumberEntered(accountNumber) {
        this.accountNumber = accountNumber
      },

      pinNumberEntered(pinNumber) {
        this.pin = pinNumber
      },

      async validatePin() {
        if (this.accountNumber.length === 7 && this.pin.length === 4) {
          this.isLoading = true
          await this.verifyPin({
            accountNumber: this.accountNumber,
            pin: this.pin,
          })

          if (!this.pinIsValidated) {
            this.isError = true
          }

          this.isLoading = false
        }
      },
    },

    computed: {
      ...mapGetters('linkAccount', ['pinIsValidated', 'pinValidationError']),
    },
  }
</script>

<style lang="scss">
  .account-pin > .the-code-input > input,
  .account-number > .the-code-input > input {
    border: solid 1px #292929;
    border-right: none;
    font-family: inherit;
    font-size: 20px;
    color: #525461;
    text-align: center;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: initial;
  }
  .account-pin > .the-code-input > input:last-child,
  .account-number > .the-code-input > input:last-child {
    border-right: solid 1px #292929;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .account-pin > .the-code-input > input:first-child,
  .account-number > .the-code-input > input:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .account-pin > .the-code-input > input:focus,
  .account-number > .the-code-input > input:focus {
    outline: none;
    border: 2px solid #41bb2e;
    caret-color: #41bb2e;
  }
  .account-pin > .the-code-input > input:focus + input,
  .account-number > .the-code-input > input:focus + input {
    border-left: none;
  }
</style>
