import Vue from 'vue'

import VueRouter from 'vue-router'

// Electricity
import Electricity from '@/views/accounts/electricity/Electricity'
import ElectricityTransactionHistory from '@/views/accounts/electricity/TransactionHistory'
import ElectricityReadingHistory from '@/views/accounts/electricity/ReadingHistory'

// Gas
import Gas from '@/views/accounts/gas/Gas'
import GasTransactionHistory from '@/views/accounts/gas/TransactionHistory'
import GasReadingHistory from '@/views/accounts/gas/ReadingHistory'

// Waste
import Waste from '@/views/accounts/waste/Waste'
import WasteTransactionHistory from '@/views/accounts/waste/TransactionHistory'
import WasteCollectionHistory from '@/views/accounts/waste/CollectionHistory'
import WasteNextCollections from '@/views/accounts/waste/NextCollections.vue'

// Link Account
import LinkAccount from '@/views/LinkAccount.vue'
import LinkAccountByEmail from '@/views/LinkAccountByEmail.vue'
import LinkAccountByText from '@/views/LinkAccountByText.vue'
import LinkAccountByPin from '@/views/LinkAccountByPin.vue'

import ValidateCode from '@/views/ValidateCode.vue'

//@todo You need to change this
import Home from '@/views/Home.vue'

// 404
import NotFound from '@/views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/add-account',
    name: 'link-account',
    component: LinkAccount,
  },
  {
    path: '/add-account/by-email',
    name: 'link-account-by-email',
    component: LinkAccountByEmail,
  },
  {
    path: '/add-account/by-text',
    name: 'link-account-by-text',
    component: LinkAccountByText,
  },
  {
    path: '/add-account/by-pin',
    name: 'link-account-by-pin',
    component: LinkAccountByPin,
  },
  {
    path: '/add-account/by-email/validate-conformation-code',
    name: 'validate-email-code',
    component: ValidateCode,
  },
  {
    path: '/add-account/by-text/validate-conformation-code',
    name: 'validate-text-code',
    component: ValidateCode,
  },
  {
    path: '/electricity',
    name: 'electricity',
    component: Electricity,
  },
  {
    path: '/electricity/transaction-history',
    name: 'electricity-transaction-history',
    component: ElectricityTransactionHistory,
  },
  {
    path: '/electricity/reading-history',
    name: 'electricity-reading-history',
    component: ElectricityReadingHistory,
  },
  {
    path: '/gas',
    name: 'gas',
    component: Gas,
  },
  {
    path: '/gas/transaction-history',
    name: 'gas-transaction-history',
    component: GasTransactionHistory,
  },
  {
    path: '/gas/reading-history',
    name: 'gas-reading-history',
    component: GasReadingHistory,
  },
  {
    path: '/waste',
    name: 'waste',
    component: Waste,
  },
  {
    path: '/waste/transaction-history',
    name: 'waste-transaction-history',
    component: WasteTransactionHistory,
  },
  {
    path: '/waste/next-collections',
    name: 'waste-next-collection',
    component: WasteNextCollections,
  },
  {
    path: '*',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
