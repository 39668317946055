<template>
  <div class="container my-6">
    <h1 class="h4 color-gradient-aquamarine-green">{{ sectionTitle }}</h1>

    <div class="bg-white shadow rounded p-4">
      <div class="row">
        <h6 class="mb-4 mt-0">Your account</h6>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
          <strong><p>Contact details</p></strong>
          <p class="my-0"><strong>Name: </strong>{{ accountName }}</p>
          <p class="my-0"><strong>Email: </strong>{{ accountEmail }}</p>
          <p class="my-0"><strong>Mobile Phone: </strong>{{ accountPhone }}</p>
          <p class="my-0"><strong>Home Phone: </strong>{{ accountHPhone }}</p>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <strong><p>Account details</p></strong>
          <p class="my-0"></p>

          <p v-if="accountMPRN" class="my-0">
            <strong>MPRN: </strong>{{ accountMPRN }}
          </p>
          <p v-if="accountGPRN" class="my-0">
            <strong>GPRN: </strong>{{ accountGPRN }}
          </p>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <strong><p>Billing address</p></strong>
          <p class="my-0">{{ accountAddress }}</p>
        </div>
      </div>
      <slot />
      <hr class="my-6" />
      <PlanDetails />
      <CommunicationPreferences />
    </div>
  </div>
</template>

<script>
  import PlanDetails from '@/components/PlanDetails.vue'
  import PlanOptions from '@/components/PlanOptions.vue'
  import CommunicationPreferences from '@/components/CommunicationPreferences.vue'

  export default {
    components: {
      PlanDetails,
      PlanOptions,
      CommunicationPreferences,
    },
    name: 'PowerAccountDetails',
    props: [
      'sectionTitle',
      'accountName',
      'accountEmail',
      'accountPhone',
      'accountHPhone',
      'accountAddress',
      'accountNumber',
      'accountMPRN',
      'accountGPRN',
    ],
  }
</script>

<style lang="scss" scoped></style>
