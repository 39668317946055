<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <h6 class="">{{ TableTitle }}</h6>
          </div>
          <div class="col-6" v-if="!showFullMonth">
            <strong>
              <router-link
                class="color-grey-700 text-decoration-none text-right d-block"
                :to="{ name: 'electricity-reading-history' }"
                >View usage history
              </router-link>
            </strong>
          </div>
        </div>

        <table class="w-100 text-left">
          <thead class="color-white bg-linear-gradient-green">
            <tr>
              <th scope="col" class="px-3 p-2">{{ TableHeadingOne }}</th>
              <th scope="col" class="px-3 p-2">{{ TableHeadingTwo }}</th>
              <th scope="col" class="px-3 p-2">{{ TableHeadingThree }}</th>
            </tr>
          </thead>
          <tbody v-for="reading in getMeterReads" :key="reading.date">
            <tr class="bg-grey">
              <td class="px-3 py-2">{{ reading.date }}</td>
              <td class="px-3 py-2">{{ reading.averageHourlyUsage }}</td>
              <td class="px-3 py-2">{{ reading.totalConsumption }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SmartMeterReadingHistory',
    props: {
      showFullMonth: {
        type: Boolean,
      },
      TableTitle: {
        type: String,
        required: true,
      },
      buttonText: {
        type: String,
      },

      TableHeadingOne: {
        type: String,
      },
      TableHeadingTwo: {
        type: String,
      },
      TableHeadingThree: {
        type: String,
      },
    },
    computed: {
      ...mapGetters('electricity', ['dailyReads']),

      getMeterReads() {
        if (this.dailyReads.length > 5 && !this.$props.showFullMonth) {
          return this.dailyReads.slice(0, 5)
        } else {
          return this.dailyReads
        }
      },
    },
  }
</script>

<style lang="scss" scoped></style>
