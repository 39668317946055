<template>
  <div class="container my-6">
    <h1 class="h4 color-gradient-aquamarine-green">{{ sectionTitle }}</h1>

    <div class="bg-white shadow rounded p-4">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountSection',
    props: {
      sectionTitle: {
        type: String,
        required: false,
      },
    },
  }
</script>

<style lang="scss" scoped></style>
