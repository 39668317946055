<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h6 class="mb-2">Smart usage chart</h6>
      </div>
      <SmartMeeterLineChart />
    </div>
  </div>
</template>

<script>
  import SmartMeeterLineChart from '@/components/electricity/SmartMeeterLineChart.vue'
  export default {
    name: 'SmartUsageChart',
    components: {
      SmartMeeterLineChart,
    },
  }
</script>

<style lang="scss" scoped></style>
