<template>
  <div>
    <div class="bg-grey rounded p-4 p-lg-4 pb-lg-4">
      <h4 class="">Want fixed monthly bills?</h4>
      <p class="pb-4">
        Manage your monthly outgoings with our Level Pay plan. We'll monitor
        your energy usage and suggest a monthly payment that will spread your
        costs out over the year
      </p>
      <a
        class="color-aquamarine hover-color-aquamarine text-decoration-none  heading-font-family d-inline-flex align-items-center"
        href="https://www.panda.ie/home/contact/"
        target="_blank"
        >Switch to level pay
        <!-- <i class="fas fa-chevron-right font-size-small ms-1"></i> Font Awesome fontawesome.com --></a
      >

      <slot />
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped></style>
