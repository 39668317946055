<template>
  <ConfirmationScreen>
    <ValidatePin />
  </ConfirmationScreen>
</template>

<script>
  import ConfirmationScreen from '@/components/layouts/ConfirmationScreen.vue'
  import ValidatePin from '@/components/LinkAccount/ValidatePin.vue'
  export default {
    components: {
      ConfirmationScreen,
      ValidatePin,
    },
  }
</script>
<style lang="scss" scoped></style>
