<template>
  <div class="position-relative" ref="submitReadsForm">
    <SmallLoader :showLoader="loading" />
    <ApiMessage :show="showMessage" :messageText="sucsessMessage" />
    <form
      @submit.prevent="onSubmit"
      id="submitreading"
      class="bg-grey rounded p-4 pb-lg-4 mt-3"
    >
      <div class="row">
        <div class="col-12">
          <div v-for="(meter, index) in registeredMeters" v-bind:key="index">
            <h6 class="mb-0">{{ meter.meterType }} Reading *</h6>
            <input
              @keypress="onlyNumber"
              v-model.number="form[index]"
              type="text"
              class="mt-2 mb-4"
              placeholder="0000000"
              aria-required="true"
              aria-invalid="false"
              required
              :maxlength="maxNumbers"
            />
          </div>
          <p>Ignore any number after the decimal point, or in red.</p>
        </div>
        <div class="d-flex flex-column-reverse col-12 col-md-6 d-flex ">
          <strong>
            <router-link
              class="color-grey-700 text-decoration-none d-block"
              :to="{ name: 'electricity-reading-history' }"
              >View usage history
            </router-link>
          </strong>
        </div>
        <div class="col-12 col-md-6 text-right d-block">
          <input
            type="submit"
            value="Submit Reading"
            class="bd-btn bd-btn-one my-t text-center mx-0 mx-md-1"
          />
        </div>
      </div>

      <slot />
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import SmallLoader from '@/components/TheSmallLoader.vue'
  import ApiMessage from '@/components/ApiMessage.vue'

  export default {
    name: 'ElectricityMeterReading',
    components: {
      SmallLoader,
      ApiMessage,
    },
    props: ['meterId'],
    data() {
      return {
        loading: false,
        isValid: false,
        showMessage: false,
        theMessage: '',
        sucsessMessage:
          'Thank you for your meter read submission, please note your read will not appear on your account immediately until it has been validated by a member of the Panda team.',
        errorMessage: '',
        form: {},
        reads: [],
        maxNumbers: 7,
      }
    },
    computed: {
      ...mapGetters('electricity', ['registeredMeters', 'numberOfMeters']),
    },
    created() {
      // console.log(this.registeredMeters)
    },
    methods: {
      ...mapActions('electricity', ['submitElectricityRead']),

      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault()
        }
      },
      onSubmit() {
        this.loading = true

        Object.keys(this.form).forEach(meterId => {
          this.reads.push({
            id: Number(meterId),
            value: Number(this.form[meterId]),
          })
        })

        this.submitElectricityRead(this.reads)
          .then(() => {
            this.form = {}
            this.loading = false
            this.showMessage = true
            setTimeout(() => {
              this.showMessage = false
            }, 10000)
          })
          .catch(e => {})
      },

      validate() {},

      scrollToForm() {
        this.$refs['submitReadsForm'].scrollIntoView({ behavior: 'smooth' })
      },
    },

    watch: {
      $route(to, from) {
        if (to.hash === '#submitreading') {
          this.scrollToForm()
        }
      },
    },

    mounted() {
      if (this.$route.hash === '#submitreading') {
        this.scrollToForm()
      }
    },
  }
</script>

<style lang="scss" scoped></style>
