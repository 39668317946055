<template>
  <div>
    <GasOverview />
    <AccountSection sectionTitle="Usage history">
      <GasMeterReadingHistory
        :showFull="true"
        TableTitle=""
        buttonText=""
        TableHeadingOne="Date"
        TableHeadingTwo="Reading"
        TableHeadingThree="Consumption Kwh"
      />
    </AccountSection>
  </div>
</template>

<script>
  import GasOverview from '@/components/gas/Overview.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'
  import GasMeterReadingHistory from '@/components/gas/MeterReadingHistory.vue'

  export default {
    name: 'GasReadingHistory',
    components: {
      AccountSection,
      GasOverview,
      GasMeterReadingHistory,
    },
  }
</script>

<style></style>
