<template>
  <div>
    <div>
      <h1 class="h6">Account verification</h1>
      <p class="">
        Please enter your email address and we’ll send you an email with an
        authentication code for us to validate your account.
      </p>

      <p class="font-size-small">
        By providing your email address, you agree to receive email from Panda.
      </p>
      <form @submit.prevent="getConfirmationCode">
        <label for="email-address">Email address</label>
        <input
          v-model="email"
          placeholder="e.g. hello@panda.ie"
          type="email"
          id="email-address"
          class="d-block mb-4"
          required
        />

        <input
          @click="goBack"
          type="button"
          value="Back"
          class="bd-btn bd-btn-two me-3 mb-3"
        />
        <input
          type="submit"
          value="Send Authentication Code"
          class="bd-btn bd-btn-one mb-md-4"
        />
      </form>

      <div>
        <p>
          Need assistance? Contact our dedicated MyPanda portal support team at
          <a href="mailto:portalsupport@panda.ie">portalsupport@panda.ie</a> or
          visit our <a href="https://www.panda.ie/help/">FAQs</a> for more
          information.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {},

    data() {
      return {
        email: '',
        errors: [],
      }
    },

    methods: {
      ...mapActions('linkAccount', ['getConfirmationCodeByEmail']),

      validEmail(email) {
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email)
      },

      goBack() {
        this.$router.push({ name: 'link-account' })
      },

      async getConfirmationCode() {
        if (this.validEmail(this.email)) {
          await this.getConfirmationCodeByEmail(this.email)
          if (this.confirmationEmailStatus) {
            this.$router.push({ name: 'validate-email-code' })
          }
        }
      },
    },
    computed: {
      ...mapGetters('linkAccount', ['confirmationEmailStatus']),
    },
  }
</script>

<style lang="scss" scoped></style>
