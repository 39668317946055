<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h6 class="">Collection history</h6>
          </div>
        </div>

        <table class="w-100 text-left">
          <thead class="color-white bg-linear-gradient-green">
            <tr>
              <th scope="col" class="px-3 p-2">Date</th>
              <th scope="col" class="px-3 p-2">Type</th>
              <th scope="col" class="px-3 p-2">Weight</th>
            </tr>
          </thead>
          <tbody v-for="(lift, index) in latestLift(10)" :key="index">
            <tr class="bg-grey">
              <td class="px-3 py-2">
                {{ lift.formattedDate }}
              </td>
              <td class="px-3 py-2">
                <i
                  :style="{ color: lift.binColourCustom }"
                  class="fas fa-circle"
                ></i
                ><span class="ms-2 d-inline-block">{{ lift.material }} </span>
              </td>
              <td class="px-3 py-2">{{ lift.weight }}kg</td>
            </tr>
          </tbody>
        </table>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CollectionHistory',

    computed: {
      ...mapGetters('waste', ['lifts']),
    },

    methods: {
      latestLift(number) {
        return this.lifts.slice(0, number)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
