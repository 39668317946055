<template>
  <div class="">
    <div class="row">
      <h6 class="mb-3 mt-3">Communication preferences</h6>
      <label>
        <toggle-button
          @change="test"
          value="On"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        />Telephone</label
      >
      <label>
        <toggle-button
          @change="test"
          :value="toogleState"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        />Direct mail</label
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'planOptions',
    data() {
      return {
        toogleState: true,
      }
    },
    methods: {
      test() {
        alert('oness')
      },
    },
  }
</script>

<style lang="scss" scoped></style>
