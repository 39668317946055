<template>
  <div>
    <ElectricityOverview />

    <AccountSection
      :sectionTitle="!isSmartMeter ? 'Reading history' : 'Usage history'"
    >
      <div v-if="isSmartMeter">
        <div class="text-right">
          <strong class="text-right">
            <a
              @click="downloadCSV($event)"
              href="#"
              class="color-grey-700 text-decoration-none text-right d-inline-block"
              >Download CSV
            </a>
          </strong>
        </div>
        <SmartMeterReadingHistory
          TableTitle=""
          buttonText=""
          TableHeadingOne="Date"
          TableHeadingTwo="Average hourly Kw"
          TableHeadingThree="Total Kw"
          :showFullMonth="true"
        />
      </div>
      <div v-else>
        <ElectricityMeterReadingHistory
          buttonText=""
          TableHeadingOne="Date"
          TableHeadingTwo="Meter reading"
          TableHeadingThree="Reading type"
          :showFull="true"
        />
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import ElectricityOverview from '@/components/electricity/Overview.vue'
  import ElectricityMeterReadingHistory from '@/components/electricity/MeterReadingHistory.vue'
  import SmartMeterReadingHistory from '@/components/electricity/SmartMeterReadingHistory.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'
  import { mapGetters, mapState } from 'vuex'

  export default {
    name: 'ElectricityReadingHistory',
    components: {
      AccountSection,
      ElectricityOverview,
      ElectricityMeterReadingHistory,
      SmartMeterReadingHistory,
    },

    methods: {
      saveAs(uri, filename) {
        const link = document.createElement('a')
        if (typeof link.download === 'string') {
          link.href = uri
          link.download = filename
          //Firefox requires the link to be in the body
          document.body.appendChild(link)
          //simulate click
          link.click()
          //remove the link when done
          document.body.removeChild(link)
        } else {
          window.open(uri)
        }
      },

      downloadCSV(event) {
        event.preventDefault()
        let csvContent =
          'data:text/csv;charset=utf-8,' +
          this.smartReadings.map(e => e.join(',')).join('\r\n')

        this.saveAs(encodeURI(csvContent), 'export.csv')
      },
    },

    computed: {
      ...mapGetters('electricity', ['isSmartMeter']),
      ...mapState('electricity', ['smartReadings']),
    },
  }
</script>

<style></style>
