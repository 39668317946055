<template>
  <div>
    <WasteOverview />
    <AccountSection sectionTitle="Transaction history">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <WasteBillingHistory title="Payments" type="payments" />
        </div>
      </div>
    </AccountSection>

    <AccountSection sectionTitle="">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 p-0 p-md-2">
          <WasteBillingHistory title="Invoices" type="invoices" />
        </div>
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import WasteOverview from '@/components/waste/Overview.vue'
  import WasteBillingHistory from '@/components/waste/BillingHistory.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'

  export default {
    name: 'TransacctionHistor',
    components: {
      AccountSection,
      WasteOverview,
      WasteBillingHistory,
    },
  }
</script>

<style></style>
