/** 
  Also, for the customer’s online account, IF CTF > 1, we shouldn’t show anything about meter reads here.

  https://pandagateway-dev.azurewebsites.net/api/power/Switch/CheckMprn
  
*/
import AuthService from '@/api/msalAuthService'
import request from '@/api/request.js'
import helpers from '@/helpers/helpers.js'

const state = {
  info: {},
  invoices: [],
  payments: [],
  readings: [],
  smartReadings: [],
  smartMeter: false,
  electricalRegisterData: [],
  payment: {},
  payByLinkUrl: '',
}

const actions = {
  async submitElectricityRead({ commit }, meeterReadData) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'power/Account/submit-electricity-read',
        {
          reads: meeterReadData,
        }
      )
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: { message: pandaApiMessage, result: pandaApiStatus },
      } = response.data

      if (gatewayHttpStatus === 200 && pandaApiStatus === 'OK') {
        console.log(response.data)
      }
    } catch (error) {
      console.log(error)
      const { StatusCode } = error.response.data

      if (StatusCode === 401) {
        console.warn('token expired')
      }
    }
  },

  async fetchSmartReading({ commit }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get(
        'power/Account/get-smart-data-usage'
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200) {
        if (data === 'serial,time,usage') {
          commit('IS_SMART_METER', false)
        } else {
          commit('IS_SMART_METER', true)
          commit('SET_SMART_READINGS', helpers.format.csvToArray(data))
        }
      }
    } catch (error) {
      console.log(error)
    }
  },

  async makeElectricityPayment({ commit }, amount) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'power/Account/make-electricity-payment',
        {
          paymentAmount: Number(amount),
        }
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: { hppPayByLink },
      } = response.data

      console.log(hppPayByLink)

      if (gatewayHttpStatus === 200 && hppPayByLink) {
        commit('MAKE_PAYMENT', hppPayByLink)
      }
    } catch (error) {
      console.log(error)
    }
  },
}

const getters = {
  electricityAccountNumber(state, getter) {
    return state.info.customerId
  },

  electricityAccountInfo(state) {
    return state.info
  },
  electricityBalance(state) {
    return helpers.format.money(state.info.currentBalance)
  },

  electricityInvoices(state) {
    const formattedInvoices = state.invoices.map(invoice => {
      invoice.type = 'invoice'
      invoice.downloadUrl = ''
      invoice.formattedDate = helpers.format.date(invoice.date)
      invoice.formattedDueDate = helpers.format.date(invoice.dueDate)
      invoice.downloadUrl = ''

      return invoice
    })

    return formattedInvoices.reverse()
  },

  electricityPayments(state) {
    const formattedPayments = state.payments.map(payment => {
      payment.date = payment.settlementDate
      payment.type = 'payment'
      payment.formattedDate = helpers.format.date(payment.settlementDate)

      return payment
    })

    return formattedPayments.reverse()
  },

  electricityBillingHistory(state, getters) {
    const transactions = [
      ...getters.electricityInvoices,
      ...getters.electricityPayments,
    ]

    return transactions.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
  },

  getElectricityBillingHistory: (state, getters) => number => {
    return getters.electricityBillingHistory.slice(0, number)
  },

  electricityBillingHistorySixItems(state, getters) {
    return getters.electricityBillingHistory.slice(0, 6)
  },

  latestBill(state, getters) {
    if (getters.electricityInvoices.length !== 0) {
      const firstInvoice = getters.electricityInvoices[0]

      return firstInvoice
    }

    return false
  },

  latestBillAmount(state, getters) {
    if (getters.latestBill) {
      return getters.latestBill.totalAmount
    }

    return 'N/A'
  },

  latestBillDate(state, getters) {
    if (getters.latestBill) {
      return getters.latestBill.formattedDate
    }

    return 'N/A'
  },

  latestBillDueDate(state, getters) {
    if (getters.latestBill) {
      return getters.latestBill.formattedDueDate
    }
    return 'N/A'
  },

  previousBillAmount(state, getters) {
    const total = getters.electricityInvoices.length

    if (total >= 2) {
      return getters.electricityInvoices[1].totalAmount
    }

    return 'N/A'
  },

  nexMeterRead(state) {
    return helpers.format.date(state.info.nextReadDate)
  },

  nextBillDate(state, getters) {
    if (getters.latestBillDate) {
      const theDate = new Date(getters.latestBillDate)
      // Add 60 days from latest bill
      theDate.setDate(theDate.getDate() + 60)

      return helpers.format.date(theDate)
    }

    return getters.nexMeterRead
  },

  meterReadings(state) {
    const readings = state.readings.map(reading => {
      reading.formattedDate = helpers.format.date(reading.readDate)
      reading.shortDate = helpers.format.shortDate(reading.readDate)

      return reading
    })

    return readings
  },

  fiveMeteReadings(state, getters) {
    if (getters.meterReadings.length > 5) {
      return getters.meterReadings.slice(0, 5)
    } else {
      return getters.meterReadings
    }
  },
  paymentOption(state) {
    return state.info.paymentOption
  },

  meterUsageDates(state) {
    if (state.readings.length > 0) {
      return state.readings
        .map(reading => {
          return helpers.format.shortDate(reading.readDate)
        })
        .reverse()
    }
  },

  meterUsageConsumption(state) {
    if (state.readings.length > 0) {
      return state.readings
        .map(reading => {
          return reading.consumption
        })
        .reverse()
    }
  },

  registeredMeters(state) {
    const meters = {}

    state.electricalRegisterData.forEach(item => {
      meters[item.meterRegisterId] = {}
      meters[item.meterRegisterId]['meterId'] = item.meterRegisterId
      meters[item.meterRegisterId][
        'meterType'
      ] = item.registerDescription.split(' ')[1]
      meters[item.meterRegisterId]['meterDescription'] =
        item.registerDescription
      meters[item.meterRegisterId]['readings'] = []

      state.readings.forEach(reading => {
        if (item.meterRegisterId === reading.meterRegisterId) {
          meters[item.meterRegisterId]['readings'].push(reading)
        }
      })
    })

    return meters
  },

  numberOfMeters(state, getters) {
    return Object.keys(getters.registeredMeters).length
  },

  isSmartMeter: state => state.smartMeter,

  // smartReads(state) {
  //   const reads = [...state.smartReadings]
  //   // 'serial', 'time', 'usage'
  //   const readsHeader = reads.shift()
  //   const readsBody = reads

  //   const formattedReads = readsBody.map(read => {
  //     const theDate = read[1].split(' ')[0]
  //     const time = read[1].split(' ')[1]
  //     const [hours, minutes] = time.split(':')
  //     // 15-05-2021
  //     const [DD, MM, YYYY] = theDate.split('-')
  //     // new Date(1995, 11, 17)  // the month is 0-indexed
  //     const formattedRead = {}
  //     const dateObject = new Date(YYYY, Number(MM) - 1, DD, hours, minutes)
  //     formattedRead.serial = read[0]
  //     formattedRead.defaultTime = read[1]
  //     formattedRead.usage = read[2]
  //     formattedRead.time = time
  //     formattedRead.date = theDate
  //     formattedRead.shortDate = helpers.format.shortDate(dateObject)
  //     formattedRead.dateObject = dateObject

  //     return formattedRead
  //   })

  //   return formattedReads
  // },

  /** 
    What I would suggest is that you sum the 48 readings per day, divide by 2, then you can display it as daily usage in Kwh.
    Total Daily Consumption = ((48x half-hour consumptions figures) / 2) kwh
    So, something like:

    28-04-2021: 23 kwh
    29-04-2021: 43 kwh

    Etc. 

    You could do a few different things, I guess.
    For examples:

    28-04-2021: 23 kwh (average hourly usage, 0.95 kwh)
    29-04-2021: 43 kwh (average hourly usage, 1.80 kwh)
  */
  smartReads(state) {
    const reads = [...state.smartReadings]
    // 'serial', 'time', 'usage'
    const readsHeader = reads.shift()
    const readsBody = reads

    const consumptions = {}

    readsBody.forEach(read => {
      const theDate = read[1].split(' ')[0]
      const time = read[1].split(' ')[1]
      const [DD, MM, YYYY] = theDate.split('-')

      const objectKey = `${YYYY}-${MM}-${DD}`

      const formattedRead = {}
      formattedRead.serial = read[0]
      formattedRead.defaultTime = read[1]
      formattedRead.usage = read[2]
      formattedRead.time = time
      formattedRead.date = theDate
      formattedRead.shortDate = helpers.format.shortDate(objectKey)

      if (!consumptions[objectKey]) {
        consumptions[objectKey] = []
        consumptions[objectKey].push(formattedRead)
      } else {
        consumptions[objectKey].push(formattedRead)
      }
    })

    const dailyReads = Object.keys(consumptions).map(readByDate => {
      const totalUsageByDate = consumptions[readByDate].reduce(
        (total, read) => {
          return total + Number(read.usage)
        },
        0 // initial value
      )

      // Total Daily Consumption = ((48x half-hour consumptions figures) / 2)
      const totalDailyConsumption = totalUsageByDate / 2
      const averageHourlyUsage =
        totalDailyConsumption / (consumptions[readByDate].length / 2)

      return {
        date: consumptions[readByDate][0].date,
        shortDate: helpers.format.shortDate(readByDate),
        defaultTime: readByDate,
        totalConsumption: totalDailyConsumption.toFixed(2),
        averageHourlyUsage: averageHourlyUsage.toFixed(4),
      }
    })

    return dailyReads.reverse()
  },

  dailyReads(state, getters) {
    if (getters.smartReads.length > 0) {
      return getters.smartReads
    }
  },

  dailyReadsShortDate(state, getters) {
    if (getters.dailyReads && getters.dailyReads.length > 0) {
      return getters.smartReads
        .map(read => {
          return read.shortDate
        })
        .reverse()
    }
  },
  dailyReadsUsage(state, getters) {
    if (getters.dailyReads && getters.dailyReads.length > 0) {
      return getters.smartReads
        .map(read => {
          return read.totalConsumption
        })
        .reverse()
    }
  },

  payByLink(state) {
    return state.payByLinkUrl
  },
}

const mutations = {
  SET_ACCOUNT_INFO(state, payload) {
    const { invoiceData, paymentData, ...info } = payload
    state.info = info
  },

  SET_INVOICES(state, payload) {
    const { invoiceData } = payload
    state.invoices = invoiceData
  },

  SET_PAYMENTS(state, payload) {
    const { paymentData } = payload
    state.payments = paymentData
  },

  SET_REGISTERED_METERS(state, payload) {
    state.electricalRegisterData = payload
  },

  SET_READINGS(state, payload) {
    state.readings = payload
  },

  IS_SMART_METER(state, payload) {
    state.smartMeter = payload
  },

  SET_SMART_READINGS(state, payload) {
    state.smartReadings = [...payload]
  },

  MAKE_PAYMENT(state, payload) {
    state.payByLinkUrl = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
