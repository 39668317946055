<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h6 class="mb-2">Average usage chart</h6>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
