<template>
  <div v-if="transactions">
    <div class="row">
      <div class="col-4">
        <h6>{{ title }}</h6>
      </div>
      <div class="col-8">
        <router-link
          :to="{ name: 'electricity-transaction-history' }"
          class="color-grey-700 text-decoration-none text-right d-block mb-3"
          >{{ buttonText }}</router-link
        >
      </div>
    </div>
    <div
      v-for="(transaction, index) in transactions"
      :key="index"
      class="trasactions"
    >
      <div class="bg-grey rounded px-3 py-2 mb-2">
        <div class="row">
          <template v-if="transaction.type === 'payment'">
            <div class="col-8">
              <i class="fas fa-euro-sign"></i><strong>Payment</strong> -
              {{ transaction.formattedDate }}
            </div>
            <div class="col-4 text-right">
              - €{{ transaction.formattedAmount }}
            </div>
          </template>

          <template v-else-if="transaction.type === 'invoice'">
            <div class="col-8">
              <i class="fas fa-file"></i><strong>Invoice</strong> -
              {{ transaction.formattedDate }}
            </div>
            <div class="col-4 text-right">
              <a
                :href="invoiceUrl"
                @click="handleClick(transaction.transactionNumber, $event)"
                class="text-decoration-none"
              >
                Download <i class="fas fa-arrow-alt-to-bottom"></i>
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    data() {
      return {
        transactions: [],
        invoiceUrl: '#',
      }
    },
    props: {
      title: {
        type: String,
      },
      buttonText: {
        type: String,
      },
      type: {
        type: String,
      },
    },
    methods: {
      ...mapActions('waste', ['fetchInvoicePdf']),

      async handleClick(id, event) {
        event.preventDefault()
        const uri = await this.fetchInvoicePdf(id)
        window.open(uri, '_blank')
      },

      asignTransactions() {
        if (this.$props.type === 'payments') {
          this.transactions = this.wastePayments
        } else if (this.$props.type === 'invoices') {
          this.transactions = this.wasteInvoices
        } else {
          this.transactions = this.wasteBillingHistorySixItems
        }
      },
    },
    created() {
      this.asignTransactions()
    },
    computed: {
      ...mapGetters('waste', [
        'wasteBillingHistorySixItems',
        'wastePayments',
        'wasteInvoices',
      ]),
    },

    watch: {
      wastePayments: {
        handler(status) {
          this.asignTransactions()
        },
        deep: true,
      },
    },
  }
</script>

<style lang="scss" scoped></style>
