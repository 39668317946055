const urls = {
  development: 'https://localhost:8080/',
  staging: 'https://panda-gateway-staging.netlify.app/',
  production: 'https://my.panda.ie/',
}

const clientIDs = {
  development: '4bb49d1d-2193-4957-9911-51665ce036e3',
  staging: '4bb49d1d-2193-4957-9911-51665ce036e3',
  production: '65362d56-6131-4630-8d93-91544097b425',
}

const authorities = {
  development: {
    signIn:
      'https://PandaGateway.b2clogin.com/PandaGateway.onmicrosoft.com/B2C_1_BDD_singing_sign_up_dev',
    resetPassword:
      'https://PandaGateway.b2clogin.com/PandaGateway.onmicrosoft.com/B2C_1_BDD_reset_dev',
  },

  staging: {
    signIn:
      'https://PandaGateway.b2clogin.com/PandaGateway.onmicrosoft.com/B2C_1_BDD_singing_sign_up_dev',
    resetPassword:
      'https://PandaGateway.b2clogin.com/PandaGateway.onmicrosoft.com/B2C_1_BDD_reset_dev',
  },

  production: {
    signIn:
      'https://PandaGateway.b2clogin.com/PandaGateway.onmicrosoft.com/B2C_1_BDD_singing_sign_up_dev',
    resetPassword:
      'https://PandaGateway.b2clogin.com/PandaGateway.onmicrosoft.com/B2C_1_BDD_reset_dev',
  },
}

export { urls, clientIDs, authorities }
