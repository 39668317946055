<template>
  <div v-html="footerHtml"></div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'Footer',

    data() {
      return {
        footerHtml: '',
      }
    },

    methods: {
      ...mapActions('layout', ['getFooter']),
    },

    async mounted() {
      this.footerHtml = await this.getFooter()
    },
  }
</script>

<style lang="scss">
  .custom-logo {
    max-width: 132px;
  }
</style>
