import AuthService from '@/api/msalAuthService'
import request from '@/api/request.js'
import helpers from '@/helpers/helpers.js'
import axios from 'axios'

const state = {
  info: {
    currentBalance: '',
    nextReadDate: '',
  },
  invoices: [],
  payments: [],
  readings: [],
  submittedReads: [],
  payByLinkUrl: '',
}

const getters = {
  gasAccountNumber(state) {
    return state.info.customerId
  },

  gasAccountInfo(state) {
    return state.info
  },

  gasBalance(state) {
    return helpers.format.money(state.info.currentBalance)
  },

  gasInvoices(state) {
    const formattedInvoices = state.invoices.map(invoice => {
      invoice.type = 'invoice'
      invoice.downloadUrl = ''
      invoice.formattedDate = helpers.format.date(invoice.date)
      invoice.formattedDueDate = helpers.format.date(invoice.dueDate)
      invoice.downloadUrl = ''

      return invoice
    })

    return formattedInvoices.reverse()
  },

  gasPayments(state) {
    const formattedPayments = state.payments.map(payment => {
      payment.date = payment.settlementDate
      payment.type = 'payment'
      payment.formattedDate = helpers.format.date(payment.settlementDate)

      return payment
    })

    return formattedPayments.reverse()
  },

  gasBillingHistory(state, getters) {
    const transactions = [...getters.gasInvoices, ...getters.gasPayments]

    return transactions.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
  },

  gasMeterPointNumber(state) {
    return state.info.meterPointNumber
  },

  nexMeterRead(state) {
    return helpers.format.date(state.info.nextReadDate)
  },

  getGasBillingHistory: (state, getters) => number => {
    return getters.gasBillingHistory.slice(0, number)
  },

  gasBillingHistorySixItems(state, getters) {
    return getters.gasBillingHistory.slice(0, 6)
  },

  latestBill(state, getters) {
    if (getters.gasInvoices.length !== 0) {
      return getters.gasInvoices[0]
    }

    return false
  },

  latestBillAmount(state, getters) {
    if (getters.latestBill) {
      return getters.latestBill.totalAmount
    }

    return 'N/A'
  },

  latestBillDate(state, getters) {
    if (getters.latestBill) {
      return getters.latestBill.formattedDate
    }

    return 'N/A'
  },

  latestBillDueDate(state, getters) {
    if (getters.latestBill) {
      return getters.latestBill.formattedDueDate
    }
    return 'N/A'
  },

  previousBillAmount(state, getters) {
    const total = getters.gasInvoices.length

    if (total >= 2) {
      return getters.gasInvoices[1].totalAmount
    }

    return 'N/A'
  },

  nexMeterRead(state) {
    return helpers.format.date(state.info.nextReadDate)
  },

  nextBillDate(state, getters) {
    if (getters.latestBillDate) {
      const theDate = new Date(getters.latestBillDate)
      // Add 60 days from latest bill
      theDate.setDate(theDate.getDate() + 60)

      return helpers.format.date(theDate)
    }

    return getters.nexMeterRead
  },

  meterReadings(state) {
    const readings = state.readings.map(reading => {
      reading.formattedDate = helpers.format.date(reading.readDate)
      reading.shortDate = helpers.format.shortDate(reading.readDate)

      return reading
    })

    return readings
  },

  paymentOption(state) {
    return state.info.paymentOption
  },

  meterUsageDates(state) {
    return state.readings
      .map(reading => {
        return helpers.format.shortDate(reading.readDate)
      })
      .reverse()
  },

  meterUsageConsumption(state) {
    return state.readings
      .map(reading => {
        return reading.consumptionKwh
      })
      .reverse()
  },

  fiveMeteReadings(state, getters) {
    if (getters.meterReadings.length > 5) {
      return getters.meterReadings.slice(0, 5)
    } else {
      return getters.meterReadings
    }
  },

  payByLink(state) {
    return state.payByLinkUrl
  },
}

const actions = {
  async submitGasRead({ commit }, volumeIndex) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'power/Account/submit-gas-read',
        {
          volumeIndex: volumeIndex,
        }
      )
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: { message: pandaApiMessage, result: pandaApiStatus },
      } = response.data

      if (gatewayHttpStatus === 200 && pandaApiStatus === 'OK') {
        console.log(response.data)
        commit('SET_SUBMITTED_READ', volumeIndex)
      }
    } catch (error) {
      console.log(error)
      const { StatusCode } = error.response.data

      if (StatusCode === 401) {
        console.warn('token expired')
      }
    }
  },

  async makeGasPayment({ commit }, amount) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'power/Account/make-gas-payment',
        {
          paymentAmount: Number(amount),
        }
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: { hppPayByLink },
      } = response.data

      console.log(hppPayByLink)

      if (gatewayHttpStatus === 200 && hppPayByLink) {
        commit('MAKE_PAYMENT', hppPayByLink)
      }
    } catch (error) {
      // console.log(error)

      const { StatusCode } = error.response.data

      if (StatusCode === 401) {
        console.warn('token expired')
      }
    }
  },
}

const mutations = {
  SET_ACCOUNT_INFO(state, payload) {
    const { invoiceData, paymentData, ...info } = payload
    state.info = info
  },

  SET_INVOICES(state, payload) {
    const { invoiceData } = payload
    state.invoices = invoiceData
  },

  SET_PAYMENTS(state, payload) {
    const { paymentData } = payload
    state.payments = paymentData
  },

  SET_READINGS(state, payload) {
    state.readings = payload
  },

  SET_SUBMITTED_READ(state, payload) {
    state.submittedReads.push(payload)
  },

  MAKE_PAYMENT(state, payload) {
    state.payByLinkUrl = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
