<template>
  <div>
    <div class="modal">
      <div
        class="modal-body pt-2 pb-2 ps-2 pe-2  pt-lg-3 ps-lg-4 pe-lg-4 pb-lg-3"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-link :to="{ name: 'home' }">
                <i class="far fa-long-arrow-alt-left"></i> Back
              </router-link>

              <h6 class="mt-3">
                Verification required to add existing account
              </h6>
              <p class="mb-2">
                Please select one of these methods to add an existing Panda
                account
              </p>
            </div>
            <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">
              <div class="card bg-grey ps-2 pe-2 pb-2 shadow">
                <h6 class="mb-1 font-size-regular">Link by email address</h6>
                <p class="mb-4 mt-0 font-size-small">
                  Here you can add an existing Panda account using the email
                  address registered with that account.
                </p>
                <router-link :to="{ name: 'link-account-by-email' }">
                  <button class="bd-btn bd-btn-one w-100">Link by Email</button>
                </router-link>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="card bg-grey ps-2 pe-2 pb-2 shadow">
                <h6 class="mb-1 font-size-regular">Link by mobile number</h6>
                <p class="mb-4 mt-0 font-size-small">
                  Here you can add an existing Panda account using the mobile
                  number registered with that account.
                </p>
                <router-link :to="{ name: 'link-account-by-text' }">
                  <button class="bd-btn bd-btn-one w-100">
                    Link by Mobile
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-12">
              <p class="mt-4">
                Need assistance? Contact our dedicated MyPanda portal support
                team at
                <a href="mailto:portalsupport@panda.ie"
                  >portalsupport@panda.ie</a
                >
                or visit our <a href="https://www.panda.ie/help/">FAQs</a> for
                more information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overview bg-palegreen position-relative">
      <HeroBg class="position-absolute top-0 end-0" />
      <div class="py-6">
        <div class="container z-2 position-relative">
          <div class="row">
            <div class="col-12 align-items-center">
              <h1 class="main-heading d-inline-flex me-4">
                Your Panda accounts
              </h1>
            </div>
          </div>
          <div class="row z-2 mt-2">
            <div v-if="isElectricityActive" class="col-sm-12 col-md-6 col-lg-4">
              <router-link tag="div" :to="{ name: 'electricity' }">
                <div
                  class="card shadow bg-linear-gradient-blue color-white p-3 rounded mb-4"
                >
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-6">
                          <strong><h6 class="mb-1">Balance</h6></strong>
                        </div>
                        <div class="col-6 text-right">View Account</div>
                      </div>
                      <p class="h3 m-0 mb-5 p-0">€{{ electricityBalance }}</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <strong><h6 class="mb-0">Electricity</h6></strong>
                  </div>
                  <div class="row">
                    <p class="mb-1">
                      {{ address }}
                    </p>
                    <p>
                      Account Number:
                      <strong>{{ electricityAccountNumber }}</strong>
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-if="isGasActive" class="col-sm-12 col-md-6 col-lg-4">
              <router-link tag="div" :to="{ name: 'gas' }">
                <div
                  class="card shadow bg-linear-gradient-blue-2 color-white p-3 rounded mb-4"
                >
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-6">
                          <strong><h6 class="mb-1">Balance</h6></strong>
                        </div>
                        <div class="col-6 text-right">View Account</div>
                      </div>
                      <p class="h3 m-0 mb-5 p-0">€{{ gasBalance }}</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <strong><h6 class="mb-0">Gas</h6></strong>
                  </div>
                  <div class="row">
                    <p class="mb-1">
                      {{ address }}
                    </p>
                    <p>
                      Account Number: <strong>{{ gasAccountNumber }}</strong>
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-if="isWasteActive" class="col-sm-12 col-md-6 col-lg-4">
              <router-link tag="div" :to="{ name: 'waste' }">
                <div
                  class="card shadow bg-radial-gradient-green color-white p-3 rounded mb-4"
                >
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-6">
                          <strong><h6 class="mb-1">Balance</h6></strong>
                        </div>
                        <div class="col-6 text-right">View Account</div>
                      </div>
                      <p class="h3 m-0 mb-5 p-0">€{{ wasteBalance }}</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <strong
                      ><h6 class="mb-0">Home recycling solutions</h6></strong
                    >
                  </div>
                  <div class="row">
                    <p class="mb-1">{{ wasteAccountAddress }}</p>
                    <p>
                      Account Number: <strong>{{ wasteAccountNumber }}</strong>
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConditionalCtas />
    <FooterCtas />
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeroBg from '@/assets/images/panda-hero-bgwhite.svg'
  import ConditionalCtas from '@/components/ConditionalCtas.vue'
  import FooterCtas from '@/components/FooterCtas.vue'

  export default {
    name: 'LinkAccount',
    components: {
      HeroBg,
      ConditionalCtas,
      FooterCtas,
    },

    data() {
      return {
        showDropdown: false,
      }
    },

    computed: {
      ...mapGetters('linkAccount', [
        'isElectricityActive',
        'isGasActive',
        'isWasteActive',
        'allAccounts',
      ]),
      ...mapGetters('power', ['address']),
      ...mapGetters('electricity', [
        'electricityBalance',
        'electricityAccountNumber',
      ]),
      ...mapGetters('gas', ['gasBalance', 'gasAccountNumber']),
      ...mapGetters('waste', [
        'wasteAccountNumber',
        'wasteAccountAddress',
        'wasteBalance',
      ]),
    },
  }
</script>

<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-body {
      background-color: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      max-width: 650px;
      .router-link-active {
        text-decoration: none;
        font-size: 14px;
      }
      .card {
        padding-top: 40px;
        border-radius: 12px;
      }
    }
  }
  .main-heading {
    font-size: 36px;
    margin-bottom: 50px !important;
    vertical-align: sub;
  }
</style>
