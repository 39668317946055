import request from '@/api/request.js'
import AuthService from '@/api/msalAuthService'

const state = {
  accountDetails: {},
  marketingOptions: {},
  fullResponse: {},
  receiveAllMarketingCommunications: false,
}

const getters = {
  accountDetails(state) {
    return state.accountDetails
  },

  fullName(state) {
    return `${state.accountDetails.firstName} ${state.accountDetails.lastName}`
  },

  address(state) {
    let address = ''

    const addressFields = [
      'unitNo',
      'houseNo',
      'street',
      'line1',
      'line2',
      'line4',
      'line5',
      'city',
      'county',
    ]
    addressFields.forEach(field => {
      if (state.accountDetails.hasOwnProperty(field)) {
        address += state.accountDetails[field] + ' '
      }
    })

    return address
  },

  email(state) {
    return state.accountDetails.emailAddress
  },

  marketingOptions(state) {
    return state.marketingOptions
  },

  askToMarketingOptionsUpdate(state) {
    return state.receiveAllMarketingCommunications
  },
}

const actions = {
  async fetchCustomerData({ commit, rootState }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get(
        'power/Account/get-customer-data'
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: {
          message: pandaApiMessage,
          result: pandaApiStatus,
          customerData: {
            marketingOptions,
            contractData: contracts,
            ...customerInfo
          },
        },
      } = response.data

      if (gatewayHttpStatus === 200 && pandaApiStatus === 'OK') {
        commit('SET_ACCOUNT_DETAILS', customerInfo)
        commit('SET_MARKETING_OPTIONS', marketingOptions)

        const electricity = Object.values(contracts).filter(contract => {
          return contract.category === 'Electricity'
        })[0]

        const gas = Object.values(contracts).filter(contract => {
          return contract.category === 'Gas'
        })[0]

        if (electricity) {
          commit('electricity/SET_ACCOUNT_INFO', electricity, { root: true })
          commit('electricity/SET_INVOICES', electricity, { root: true })
          commit('electricity/SET_PAYMENTS', electricity, { root: true })
          commit('linkAccount/SET_ACTIVE_ACCOUNT', 'electricity', {
            root: true,
          })
        }

        if (gas) {
          commit('gas/SET_ACCOUNT_INFO', gas, { root: true })
          commit('gas/SET_INVOICES', gas, { root: true })
          commit('gas/SET_PAYMENTS', gas, { root: true })
          commit('linkAccount/SET_ACTIVE_ACCOUNT', 'gas', {
            root: true,
          })
        }
      } else {
        throw `Gateway API ${gatewayHttpStatus}
               Panda API Error ${pandaApiMessage}`
      }
    } catch (error) {
      const { StatusCode } = error.response.data

      if (StatusCode === 401) {
        console.warn('token expired')
      }
    }
  },

  /**
   *
   * @param {*} param0
   */
  async fetchReadings({ commit, rootGetters }) {
    // if (rootGetters['linkAccount/isWasteActive']) {
    // }
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get('power/Account/get-read-data')
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: {
          message: pandaApiMessage,
          result: pandaApiStatus,
          readData: { electricalReadData, electricalRegisterData, gasReadData },
        },
      } = response.data

      // console.log(response.data)

      if (gatewayHttpStatus === 200 && pandaApiStatus === 'OK') {
        commit('electricity/SET_REGISTERED_METERS', electricalRegisterData, {
          root: true,
        })
        commit('electricity/SET_READINGS', electricalReadData, { root: true })
        commit('gas/SET_READINGS', gasReadData, { root: true })
      }
    } catch (error) {
      console.log('fetchReadings')
      const { StatusCode } = error.response.data
      if (StatusCode === 401) {
        console.warn('token expired')
      }
    }
  },

  async fetchInvoiceData({ commit }, invoiceNumber) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        '/power/Account/invoice-download',
        {
          invoiceId: invoiceNumber,
        }
      )

      const { apiHttpStatus: gatewayHttpStatus, data } = response.data

      if (
        gatewayHttpStatus === 200 &&
        data.invoiceFileName &&
        data.invoiceFile
      ) {
        return data
      }
    } catch (error) {
      console.log(error)
      const { StatusCode } = error.response.data

      if (StatusCode === 401) {
        console.warn('token expired')
      }
    }
  },

  async updatePowerMarketingCommunications(
    { commit, dispatch },
    marketingOptionIds
  ) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'power/Account/update-marketing-communications',
        {
          marketingOptions: marketingOptionIds,
        }
      )
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: { message: pandaApiMessage, result: pandaApiStatus },
      } = response.data

      if (gatewayHttpStatus === 200 && pandaApiStatus === 'OK') {
        if (marketingOptionIds === '') {
          commit('UPDATE_MARKETING_COMMUNICATIONS', false)
        } else {
          commit('UPDATE_MARKETING_COMMUNICATIONS', true)
        }

        // Refresh user data
        await dispatch('fetchCustomerData')
      }
    } catch (error) {
      console.log(error)
    }
  },
}

const mutations = {
  SET_ACCOUNT_DETAILS(state, payload) {
    const clean = obj => {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }

    state.accountDetails = clean(payload)
  },

  SET_MARKETING_OPTIONS(state, payload) {
    payload.forEach(marketingOption => {
      if (marketingOption.selected === null) {
        state.receiveAllMarketingCommunications = true
      }
    })

    state.marketingOptions = { ...payload }
  },

  SET_CONTRACTS(state, payload) {
    state.contracts = { ...payload.contracts }
    state.allContracts = { ...payload }
  },

  SET_READING_HISTORY(state, payload) {
    state.readingHistory = { ...payload }
  },

  SET_TRANSACTION_HISTORY(state, payload) {
    state.transactionHistory = { ...payload }
  },

  UPDATE_MARKETING_COMMUNICATIONS(state, payload) {
    state.receiveMarketingCommunications = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
