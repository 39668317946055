<script>
  import { Line } from 'vue-chartjs'
  import { mapGetters } from 'vuex'
  export default {
    extends: Line,
    data() {
      return {
        datasets: [],
        labels: [],
      }
    },
    methods: {
      initChart() {
        this.asignLabel()
        this.asignDatasets()
        this.renderChart(
          {
            labels: this.labels,
            datasets: this.datasets,
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
              text: 'Ussage',
            },
          }
        )
      },
      asignLabel() {
        if (this.numberOfMeters === 1) {
          this.labels = this.meterUsageDates
        } else {
          this.labels = Object.values(this.registeredMeters)[0].readings.map(
            read => {
              return read.shortDate
            }
          )
        }
      },
      asignDatasets() {
        if (this.numberOfMeters === 1) {
          this.datasets.push({
            label: 'Electricity Consumption',
            data: this.meterUsageConsumption,
            backgroundColor: 'transparent',
            borderColor: 'rgba(1, 116, 188, 0.50)',
            pointBackgroundColor: 'rgba(171, 71, 188, 1)',
          })
        } else {
          Object.values(this.registeredMeters).forEach(meter => {
            let meterConsumption = meter.readings.map(item => {
              return item.consumption
            })

            this.datasets.push({
              label: `${meter.meterType}`,
              data: meterConsumption,
              backgroundColor: 'transparent',
              borderColor: 'rgba(1, 116, 188, 0.50)',
              pointBackgroundColor: 'rgba(171, 71, 188, 1)',
            })
          })
        }
      },
    },

    mounted() {
      this.initChart()
    },

    computed: {
      ...mapGetters('electricity', [
        'numberOfMeters',
        'meterUsageDates',
        'meterUsageConsumption',
        'registeredMeters',
      ]),
    },

    watch: {
      meterUsageDates: {
        handler(status) {
          this.initChart()
        },
        deep: true,
      },
    },
  }
</script>
