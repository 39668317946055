<template>
  <div>
    <header class="page-header page-header-inner text-center pt-5 pt-lg-7">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col col-12 col-md-10 col-lg-7">
            <h1 class="page-title color-gradient-aquamarine-green">
              (404) Page Not Found
            </h1>
            <h2 class="h5 fw-normal global-font-family">
              Oops it seems you’ve stumbled out of the Bamboo forest. You can go
              back or try looking on our
              <a href="https://www.panda.ie/help/">Help Centre</a> if you need a
              paw.
            </h2>
          </div>
        </div>
      </div>
    </header>

    <!-- <FooterCtas /> -->
  </div>
</template>

<script>
  import FooterCtas from '@/components/FooterCtas.vue'
  export default {
    name: 'notFound',
    components: {
      // ConditionalCtas,
      FooterCtas,
    },
  }
</script>

<style scoped>
  .page-header {
    background-image: url('https://www.panda.ie/wp-content/themes/bigrig/assets/images/paw-corner.svg');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 10rem;
  }

  .page-header-inner {
    /* background-color: #f8fdf7; */
    min-height: 26rem;
  }

  @media (min-width: 62rem) {
    .page-header {
      background-size: 15rem;
    }
  }

  @media (min-width: 75rem) {
    .page-header {
      background-size: 35%;
    }
  }
</style>
