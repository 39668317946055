<template>
  <div class="overview position-relative hero-bg">
    <div class="bg-palegreen py-7">
      <div class="container">
        <div class="row">
          <div class="col-12 z-2 position-relative">
            <div
              class="d-flex align-items-center align-items-lg-end align-items-xxl-start mb-4"
            >
              <DumpsterIcon class="d-flex me-2" />
              <h1 class="h3 mb-0">
                Home Recycling
              </h1>
            </div>
            <p class="font-size-larger mb-0 pb-0" v-if="wasteAccountAddress">
              {{ wasteAccountAddress }}
            </p>
            <p class="mt-1 mb-6">Account number: {{ wasteAccountNumber }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h4>Account Summary</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4 d-grid">
            <div
              class="card shadow bg-radial-gradient-green color-white p-3 rounded mb-4"
            >
              <div class="row">
                <div class="col">
                  <strong><h6 class="mb-0">Current balance</h6></strong>
                  <p class="h3 m-0 p-0">€{{ wasteBalance }}</p>
                  <p v-if="wasteBalanceMessage">{{ wasteBalanceMessage }}</p>
                  <p class="mt-0 mb-3" v-if="wasteLatestBill">
                    Issued on:
                    <strong>{{ wasteLatestBill.formattedDate }}</strong>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <!-- <button class="bd-btn bd-btn-three mb-4">Pay Now</button> -->
                  <router-link
                    :to="{ name: 'waste', hash: '#makepayment' }"
                    tag="button"
                    class="bd-btn bd-btn-three mb-4"
                    >Make a payment</router-link
                  >
                </div>
              </div>
              <div class="row">
                <strong>
                  <router-link
                    :to="{ name: 'waste-transaction-history' }"
                    class="text-decoration-none color-white d-block"
                    >View transaction history</router-link
                  >
                </strong>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 d-grid">
            <div class="card bg-white shadow p-3 rounded mb-4">
              <div class="row">
                <div class="col">
                  <strong><h6 class="mb-0">Next collection</h6></strong>
                  <p class="h3 m-0">{{ nextCollectionWeekday }}</p>
                  <p class="mt-0 mb-3" v-if="wasteLatestBill">
                    <strong>{{ nextCollectionDate }}</strong>
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <strong><p class="my-0">Collection Type</p></strong>
                  <p class="my-0">{{ nextCollectionType }}</p>
                </div>
              </div>
              <div class="row">
                <strong>
                  <router-link
                    :to="{ name: 'waste-next-collection' }"
                    class="text-decoration-none"
                    >View collection calendar
                    <i class="fas fa-chevron-right"></i>
                  </router-link>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import DumpsterIcon from '@/assets/images/dumpster.svg'

  export default {
    name: 'WasteOverview',
    components: {
      DumpsterIcon,
    },
    computed: {
      ...mapGetters('waste', [
        'wasteAccountAddress',
        'wasteAccountNumber',
        'wasteBalance',
        'nextCollectionDate',
        'nextCollectionWeekday',
        'nextCollectionType',
        'wasteLatestBill',
        'wasteBalanceMessage',
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  .color-white {
    color: white !important;
  }
</style>
