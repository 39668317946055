<template>
  <div>
    <GasOverview />
    <AccountSection sectionTitle="Billing & payment">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-7 p-0 p-md-2">
          <GasLatestBilling />
          <GasBillingHistory
            title="Billing history"
            buttonText="View transaction history"
          />
        </div>
        <div class="col-12 col-md-12 col-lg-5 p-0 p-md-2">
          <GasInformBox />
          <a
            href="https://www.panda.ie/panda-home/skip-bag-hire/"
            target="_blank"
          >
            <div class="mt-5">
              <img src="@/assets/images/cta-skips.png" />
            </div>
          </a>
        </div>
      </div>
    </AccountSection>
    <AccountSection sectionTitle="Submit meter reading">
      <div class="row">
        <MeterInfo TransactionHistory="/gas/transaction-history" />
        <div class="col-12 col-md-12 col-lg-7">
          <SubmitReading />
        </div>

        <div class="col-12 col-md-12 col-lg-5 mt-3">
          <TheInfoBox
            heading="How do I read my meter?"
            body="Make a note of the figures from left to right, ignoring the red numbers or if they have a red box surrounding it. Meter readings help keep your bill accurate. ESB Networks or Gas Networks Ireland will attempt to read your meters four times a year.
Need more help?"
            linkText="Visit our help centre"
            link="https://www.panda.ie/faq-category/meter-readings/#faqs"
          />
        </div>
        <hr class="my-7" />
        <!-- Meter reading history -->
        <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
          <GasMeterReadingHistory
            TableTitle="Meter reading history"
            buttonText=""
            TableHeadingOne="Date"
            TableHeadingTwo="Reading"
            TableHeadingThree="Consumption Kwh"
          />
        </div>
        <!-- Average usage chart -->
        <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
          <div class="row">
            <div class="col-12">
              <h6 class="mb-2">Average usage chart</h6>
            </div>
          </div>
          <LineChart />
        </div>
      </div>
    </AccountSection>
    <PowerAccountDetails
      sectionTitle="Account details"
      :accountName="fullName"
      :accountEmail="accountDetails.emailAddress"
      :accountPhone="accountDetails.phoneNo1"
      :accountHPhone="accountDetails.phoneNo2"
      :accountNumber="gasAccountNumber"
      :accountNPRN="false"
      :accountGPRN="gasAccountInfo.meterPointNumber"
      :accountAddress="address"
    />
  </div>
</template>

<script>
  import AccountSection from '@/components/layouts/AccountSection.vue'
  import GasOverview from '@/components/gas/Overview.vue'
  import GasLatestBilling from '@/components/gas/LatestBilling.vue'
  import SubmitReading from '@/components/gas/SubmitReading.vue'

  import GasInformBox from '@/components/gas/InformBox.vue'

  import GasBillingHistory from '@/components/gas/BillingHistory.vue'

  import MeterInfo from '@/components/gas/MeterInfo.vue'

  import PowerAccountDetails from '@/components/PowerAccountDetails.vue'

  import GasMeterReadingHistory from '@/components/gas/MeterReadingHistory.vue'
  import LineChart from '@/components/gas/LineChart.vue'

  import TheInfoBox from '@/components/TheInfoBox.vue'

  import { mapGetters } from 'vuex'

  export default {
    name: 'Gas',
    components: {
      GasOverview,
      AccountSection,
      GasLatestBilling,
      GasInformBox,
      GasBillingHistory,
      SubmitReading,
      MeterInfo,
      GasMeterReadingHistory,
      PowerAccountDetails,
      LineChart,
      TheInfoBox,
    },
    computed: {
      ...mapGetters('power', ['accountDetails', 'fullName', 'address']),
      ...mapGetters('gas', ['gasAccountInfo', 'gasAccountNumber']),
    },
  }
</script>

<style></style>
