import Vue from 'vue'
import Vuex from 'vuex'

import layout from './modules/layout'
import linkAccount from './modules/linkAccount'
import power from './modules/power'
import electricity from './modules/electricity'
import gas from './modules/gas'
import waste from './modules/waste'

// import auth from './modules/auth'
// import errorSystem from './modules/errorSystem'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    // auth,
    layout,
    linkAccount,
    power,
    electricity,
    gas,
    waste,
    // errorSystem,
  },
})
