<template>
  <div v-if="!isSmartMeter">
    <div class="row">
      <div class="col-12">
        <h6 class="mb-4">Electricity</h6>
        <p class="m-0 mb-1">
          <strong> MPRN: {{ electricityAccountInfo.meterPointNumber }} </strong>
        </p>
        <div v-for="(meter, index) in registeredMeters" v-bind:key="index">
          <p class="m-0">
            <strong>
              Meter ID: {{ meter.meterId }} {{ meter.meterType }}
            </strong>
          </p>
        </div>
      </div>
      <div class="col-8">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'ElectricityMeterInfo',
    computed: {
      ...mapGetters('electricity', [
        'electricityAccountInfo',
        'registeredMeters',
        'isSmartMeter',
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 50px;
  }
</style>
