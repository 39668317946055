<template>
  <div>
    <ConfirmationScreen>
      <ValidateConfirmationCode />
    </ConfirmationScreen>
  </div>
</template>

<script>
  import ConfirmationScreen from '@/components/layouts/ConfirmationScreen.vue'
  import ValidateConfirmationCode from '@/components/LinkAccount/ValidateConfirmationCode.vue'
  export default {
    components: {
      ConfirmationScreen,
      ValidateConfirmationCode,
    },
  }
</script>
<style lang="scss" scoped></style>
