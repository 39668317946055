<template>
  <div
    v-if="!isElectricityActive && !isGasActive && !isWasteActive"
    class="col-12 col-sm-12 col-md-6 col-lg-4 d-flex"
  >
    <div class="shadow p-3 rounded mb-4 bg-white">
      <div class="row">
        <div class="col">
          <strong
            ><p class="mb-1">
              Thank you for registering with My Panda
            </p></strong
          >
          <p>
            Unfortunately we cannot find any utility accounts associated with
            this email address.
          </p>
          <p>
            Add an existing account below by using an alternative email address
            or phone number.
          </p>

          <router-link
            tag="button"
            :to="{ name: 'link-account' }"
            class="bd-btn bd-btn-one"
            >Link Account</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'NoAccountFound',
    computed: {
      ...mapGetters('linkAccount', [
        'isElectricityActive',
        'isGasActive',
        'isWasteActive',
      ]),
    },
  }
</script>

<style></style>
