<template>
  <div>
    <div
      class="bg-grey rounded p-4 p-lg-4 pb-lg-4 mb-5 position-relative"
      ref="paymentForm"
    >
      <TheSmallLoader :showLoader="isLoading" />
      <ApiMessage :messageText="theMessage" :show="showMessage" />
      <div class="row">
        <div class="col-12 col-md-8" v-if="wasteLatestBill">
          <h6 class="mb-0">Latest bill</h6>
          <p class="h3 m-0">€{{ wasteLatestBill.formattedAmount }}</p>
          <p class="mt-0 mb-2 mb-md-0">
            Issued on: <strong>{{ wasteLatestBill.formattedDate }} </strong>
          </p>
        </div>
        <div
          class="d-flex flex-column col-12 col-md-4"
          v-bind:style="{ opacity: columnOpacity }"
        >
          <button
            @click="showPaymentForm"
            class="bd-btn bd-btn-one mb-2 text-center mx-0 mx-md-1"
          >
            Pay now
          </button>

          <button
            @click="handleClick(wasteLatestBill.transactionNumber, $event)"
            class="bd-btn bd-btn-one mb-2 text-center mx-0 mx-sm-1 mx-md-1"
          >
            Download
          </button>
        </div>
      </div>
      <div v-show="showPaymentField" class="row">
        <strong>Amount to pay *</strong>
      </div>
      <div v-show="showPaymentField" class="row mt-2 mb-2">
        <div class="col-12 col-md-8">
          <input
            v-model="paymnetAmmount"
            @keypress="onlyNumber"
            type="text"
            name=""
            id=""
            class="w-100"
            placeholder="€00.00"
          />
        </div>
        <div
          class="d-flex flex-column col-12 col-md-4 align-items-center justify-content-center"
        >
          <button
            @click="processForm"
            class="bd-btn bd-btn-one text-center w-100 justify-content-center"
          >
            Pay now
          </button>
        </div>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
  import TheSmallLoader from '@/components/TheSmallLoader.vue'
  import ApiMessage from '@/components/ApiMessage.vue'

  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'LatesWastetBiling',
    components: {
      ApiMessage,
      TheSmallLoader,
    },
    data() {
      return {
        columnOpacity: 1,
        showPaymentField: false,
        paymnetAmmount: null,
        showMessage: false,
        isLoading: false,
        theMessage: '',
        succsessMessage:
          'Payments can take up to 24 working hours to be processed. We advise that payment should be made 2 working days before your bin collection day. Please do not reply to this e-mail as this account is not monitored. Kindest Regards,',
        latestBillAmount: '',
        latestBillDate: '',
      }
    },
    computed: {
      ...mapGetters('waste', ['payByLink', 'wasteLatestBill']),
    },
    methods: {
      ...mapActions('waste', ['makePayment', 'fetchInvoicePdf']),

      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          $event.preventDefault()
        }
      },
      showPaymentForm() {
        this.columnOpacity = 0
        this.showPaymentField = true
      },
      processForm() {
        if (this.paymnetAmmount && this.paymnetAmmount !== 0) {
          this.isLoading = true
          this.makePayment(this.paymnetAmmount)
            .then(() => {
              this.isLoading = !this.isLoading
              this.showMessage = true
              this.theMessage = 'Redirecting to Global Payments'

              if (this.payByLink) {
                window.location.replace(this.payByLink)
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      scrollToForm() {
        this.showPaymentForm()
        this.$refs['paymentForm'].scrollIntoView({ behavior: 'smooth' })
      },

      async handleClick(id, event) {
        event.preventDefault()
        const uri = await this.fetchInvoicePdf(id)
        window.open(uri, '_blank')
      },
    },
    watch: {
      $route(to, from) {
        if (to.hash === '#makepayment') {
          this.scrollToForm()
        }
      },
    },
    mounted() {
      if (this.$route.hash === '#makepayment') {
        this.scrollToForm()
      }
    },
  }
</script>

<style lang="scss" scoped></style>
