import AuthService from '@/api/msalAuthService'

import request from '@/api/request.js'

import helpers from '@/helpers/helpers.js'

// Custom bin colors
const customBinColors = {
  Waste: '#292929',
  Recycling: '#7ccd37',
  Compost: '#977254',
  Glass: '#d5b295',
}

const state = {
  account: {},
  nextCollections: [],
  lifts: [],
  transactions: [],
  payment: {},
  payByLinkUrl: '',
  calendarUrl: '',
  receiveMarketingCommunications: null,
}

const getters = {
  wasteAccountInfo(state) {
    return state.account
  },

  wasteAccountAddress(state) {
    let address = ''
    const addressFields = [
      'address1',
      'address2',
      'address3',
      'address4',
      'address6',
      'address7',
      'address8',
    ]
    addressFields.forEach(field => {
      if (state.account.hasOwnProperty(field)) {
        address += state.account[field] + ' '
      }
    })

    return address
  },

  wasteAccountNumber(state) {
    return state.account.accountNumber
  },

  wasteAccountEmail(state) {
    return state.account.email
  },

  wasteBalance(state) {
    return helpers.format.money(state.account.balance)
  },

  wasteBalanceMessage(state) {
    const number = parseInt(state.account.balance)
    // check if number is greater than 0
    if (number > 0) {
      return ''
    }

    return false
  },

  wasteTransactions(state) {
    if (state.transactions.length > 0) {
      const transactions = state.transactions.map(transaction => {
        transaction.formattedDate = helpers.format.date(
          transaction.transactionDate
        )

        transaction.formattedAmount = helpers.format.money(
          transaction.transactionAmount
        )

        if (transaction.creditDebit === 'Debit') {
          transaction.type = 'invoice'
        } else if (transaction.creditDebit === 'Credit') {
          transaction.type = 'payment'
        }

        return transaction
      })

      return transactions
    }
  },

  wasteBillingHistorySixItems(state, getters) {
    if (getters.wasteTransactions) {
      if (getters.wasteTransactions.length > 6) {
        return getters.wasteTransactions.slice(0, 6)
      } else if (getters.wasteTransactions.length > 0) {
        return getters.wasteTransactions.length
      }
    }
  },

  wasteInvoices(state, getters) {
    if (getters.wasteTransactions) {
      const formattedInvoices = getters.wasteTransactions.filter(
        transaction => {
          if (transaction.creditDebit === 'Debit') {
            return transaction
          }
        }
      )
      return formattedInvoices
    }
  },

  wasteLatestBill(state, getters) {
    if (getters.wasteInvoices) {
      return getters.wasteInvoices[0]
    }
  },

  wastePayments(state, getters) {
    if (getters.wasteTransactions) {
      const formattedPayments = getters.wasteTransactions.filter(
        transaction => {
          if (transaction.creditDebit === 'Credit') {
            return transaction
          }
        }
      )

      return formattedPayments
    }
  },

  nextCollectionDate(state) {
    if (state.nextCollections.length !== 0) {
      return helpers.format.date(state.nextCollections[0].nextCollectionDate)
    }
    return 'N/A'
  },

  nextCollectionWeekday(state) {
    if (state.nextCollections.length !== 0) {
      return helpers.format.weekday(state.nextCollections[0].nextCollectionDate)
    }
    return 'N/A'
  },

  //@todo refactor
  nextCollectionType(state) {
    if (state.nextCollections.length !== 0) {
      if (
        state.nextCollections[0].nextCollectionDate ===
          state.nextCollections[1].nextCollectionDate &&
        state.nextCollections[0].nextCollectionDate ===
          state.nextCollections[2].nextCollectionDate
      ) {
        return `${state.nextCollections[0].binType}, ${state.nextCollections[1].binType}, ${state.nextCollections[2].binType}`
      }

      if (
        state.nextCollections[0].nextCollectionDate ===
        state.nextCollections[1].nextCollectionDate
      ) {
        return `${state.nextCollections[0].binType}, ${state.nextCollections[1].binType}`
      }

      return state.nextCollections[0].binType
    }

    return 'N/A'
  },

  nextCollections(state) {
    return state.nextCollections.map(collection => {
      collection.formattedDate = helpers.format.date(
        collection.nextCollectionDate
      )

      collection.weekday = helpers.format.weekday(collection.nextCollectionDate)
      collection.binColourCustom = customBinColors[collection.binType]

      return collection
    })
  },

  payByLink(state) {
    return state.payByLinkUrl
  },

  lifts(state, getters) {
    return state.lifts.map(lift => {
      lift.formattedDate = helpers.format.date(lift.liftDate)

      lift.binColourCustom = customBinColors[lift.material]

      return lift
    })
  },

  calendarUrl(state) {
    return state.calendarUrl
  },
}

const actions = {
  async fetchAccount({ commit, rootGetters }) {
    // isWasteActive
    if (rootGetters['linkAccount/isWasteActive']) {
      try {
        const token = await AuthService.getIdToken()
        const axiosInstance = request.setBearer(token)
        const response = await axiosInstance.get('domestic/Account')

        const {
          apiHttpStatus: gatewayHttpStatus,
          apiMessages: gatewayApiMessages,
          data,
        } = response.data

        if (gatewayHttpStatus === 200) {
          commit('SET_ACCOUNT', data)
        } else {
          throw `Gateway API ${gatewayHttpStatus}`
        }
      } catch (error) {
        console.log(error)
      }
    }
  },

  async fetchNextCollections({ commit, rootGetters }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get(
        'domestic/Account/next-collections'
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200) {
        commit('SET_NEXT_COLLECTIONS', data)
      } else {
        throw `Gateway API ${gatewayHttpStatus}`
      }
    } catch (error) {
      console.log(error)
    }
  },

  async fetchLifts({ commit, rootGetters }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get('domestic/Account/lifts')

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200) {
        commit('SET_LIFTS', data)
      } else {
        throw `Gateway API ${gatewayHttpStatus}`
      }
    } catch (error) {
      console.log(error)
    }
  },

  async fetchTransactions({ commit, rootGetters }) {
    try {
      const token = await AuthService.getIdToken()

      const axiosInstance = request.setBearer(token)

      const response = await axiosInstance.get('domestic/Account/transactions')

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200) {
        commit('SET_TRANSACTIONS', data)
      } else {
        throw `Gateway API ${gatewayHttpStatus}`
      }
    } catch (error) {
      console.log(error)
    }
  },

  async makePayment({ commit, getters }, amount) {
    try {
      const token = await AuthService.getIdToken()

      const myHeaders = new Headers()

      myHeaders.append('Authorization', `Bearer ${token}`)
      myHeaders.append('Content-Type', 'application/json')

      const requestData = JSON.stringify({
        amountToPay: Number(amount),
        emailReceipt: getters.wasteAccountEmail,
        isMobile: false,
      })

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: requestData,
        redirect: 'follow',
      }

      const response = await fetch(
        `${request.baseURL}/domestic/make-payment`,
        requestOptions
      )

      const responseText = await response.text()

      const {
        apiHttpStatus,
        apiMessages,
        data: { hppPayByLink },
      } = JSON.parse(responseText)

      if (apiHttpStatus === 200 && hppPayByLink) {
        commit('SET_PAYMENT', hppPayByLink)
      }

      // commit('SET_PAYMENT', data)
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * Generate a URL that is valid for 1 hour to download the invoice.
   *
   * /api/domestic/Account/invoice-pdf
   * @param {integer($int32)} transactionNumber
   */

  async fetchInvoicePdf({ commit }, transactionNumber) {
    const token = await AuthService.getIdToken()
    const axiosInstance = request.setBearer(token)

    const response = await axiosInstance.get('domestic/Account/invoice-pdf', {
      params: {
        transactionNumber: transactionNumber,
      },
    })

    const {
      apiHttpStatus: gatewayHttpStatus,
      apiMessages: gatewayApiMessages,
      data: { sasUri: uri },
    } = response.data

    if (gatewayHttpStatus === 200 && uri) {
      console.log(uri)
      return uri
    }
  },

  async fetchCollectionsCalendarUrl({ commit }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get('domestic/Account/calendar-url')

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: { calendarUrl },
      } = response.data

      if (gatewayHttpStatus === 200 && calendarUrl) {
        commit('SET_CALENDAR_URL', calendarUrl)
      }
    } catch (error) {
      console.log(error)
    }
  },

  async updateWasteMarketingCommunications({ commit }, choice) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'domestic/Account/update-marketing-communications',
        {
          receiveMarketingCommunications: choice,
        }
      )
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200 && data) {
        commit('UPDATE_MARKETING_COMMUNICATIONS', choice)
      }
    } catch (error) {
      console.log(error)
    }
  },
}

const mutations = {
  SET_ACCOUNT(state, payload) {
    const clean = obj => {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ''
        ) {
          delete obj[propName]
        }
      }
      return obj
    }

    state.account = clean(payload)
  },
  SET_NEXT_COLLECTIONS(state, payload) {
    state.nextCollections = [...payload]
  },

  SET_LIFTS(state, payload) {
    state.lifts = [...payload]
  },

  SET_TRANSACTIONS(state, payload) {
    state.transactions = [...payload]
  },

  SET_PAYMENT(state, payload) {
    state.payByLinkUrl = payload
  },

  SET_CALENDAR_URL(state, payload) {
    state.calendarUrl = payload
  },

  UPDATE_MARKETING_COMMUNICATIONS(state, payload) {
    state.receiveMarketingCommunications = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
