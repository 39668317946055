<template>
  <div class="container-fluid container-xxxl mt-6 px-md-4 px-xxxl-2">
    <div class="row">
      <div class="col col-12 col-md-6">
        <div
          class="footer-call-to-actions__item text-center px-2 pt-5 px-md-3 pt-xl-8 mb-3 position-relative"
        >
          <figure>
            <img
              src="https://www.panda.ie/wp-content/uploads/2021/04/panda-sustainability-592x640.jpg"
              loading="lazy"
              alt=""
              width="592"
              height="640"
              srcset="
                https://www.panda.ie/wp-content/uploads/2021/04/panda-sustainability-792x840.jpg 792w,
                https://www.panda.ie/wp-content/uploads/2021/04/panda-sustainability-592x640.jpg 592w,
                https://www.panda.ie/wp-content/uploads/2021/04/panda-sustainability-343x375.jpg 343w
              "
              sizes="(min-width: 1400px) 792px, (min-width: 992px) 592px, 343px"
              class="position-absolute w-100 h-100 bg-image top-0 start-0 bg-image-cover bg-image-top-left z0"
            />
          </figure>
          <div class="z1">
            <h3>At Panda we don’t just talk sustainability, we do it</h3>
            <a
              class="color-aquamarine hover-color-aquamarine visited-color-aquamarine text-decoration-none font-size-large heading-font-family d-inline-flex align-items-center"
              href="https://www.panda.ie/panda-home/sustainability/"
              target=""
              >Our Commitments
              <svg
                class="svg-inline--fa fa-chevron-right fa-w-10 font-size-small ms-1"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                ></path></svg
              ><!-- <i class="fas fa-chevron-right font-size-small ms-1"></i> Font Awesome fontawesome.com --></a
            >
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-6">
        <div
          class="footer-call-to-actions__item text-center px-2 pt-5 px-md-3 pt-xl-8 mb-3 position-relative"
        >
          <figure class="">
            <img
              src="https://www.panda.ie/wp-content/uploads/2021/04/panda-business-energy-05-592x640.jpg"
              loading="lazy"
              alt=""
              width="592"
              height="640"
              srcset="
                https://www.panda.ie/wp-content/uploads/2021/04/panda-business-energy-05-792x668.jpg 792w,
                https://www.panda.ie/wp-content/uploads/2021/04/panda-business-energy-05-592x640.jpg 592w,
                https://www.panda.ie/wp-content/uploads/2021/04/panda-business-energy-05-343x375.jpg 343w
              "
              sizes="(min-width: 1400px) 792px, (min-width: 992px) 592px, 343px"
              class="position-absolute w-100 h-100 bg-image top-0 start-0 bg-image-cover bg-image-top-left z0"
            />
          </figure>
          <div class="z1">
            <h3>
              Want to find out more about our home or business solutions?
            </h3>
            <a
              class="color-aquamarine hover-color-aquamarine visited-color-aquamarine text-decoration-none font-size-large heading-font-family d-inline-flex align-items-center"
              href="https://www.panda.ie/panda-home/contact-us/"
              target=""
              >Contact Us
              <svg
                class="svg-inline--fa fa-chevron-right fa-w-10 font-size-small ms-1"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                ></path></svg
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FooterCtas',
  }
</script>

<style></style>
