<template>
  <div>
    <WasteOverview />
    <AccountSection sectionTitle="Billing & payment">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-7 p-0 p-md-2">
          <WasteLatestBilling />
          <WasteBillingHistory title="Billing history" />
        </div>
        <div class="col-12 col-md-12 col-lg-5 p-0 p-md-2">
          <WasteInformBox />
          <a
            href="https://www.panda.ie/panda-home/skip-bag-hire/"
            target="_blank"
          >
            <div class="mt-5">
              <img src="@/assets/images/cta-skips.png" />
            </div>
          </a>
        </div>
      </div>
    </AccountSection>

    <AccountSection sectionTitle="Your collections">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
          <NextCollections />
        </div>
        <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
          <CollectionHistory />
        </div>
      </div>
    </AccountSection>
  </div>
</template>

<script>
  import WasteOverview from '@/components/waste/Overview.vue'
  import AccountSection from '@/components/layouts/AccountSection.vue'

  import WasteLatestBilling from '@/components/waste/LatestBilling.vue'
  import WasteBillingHistory from '@/components/waste/BillingHistory.vue'

  import WasteInformBox from '@/components/waste/InformBox.vue'

  import NextCollections from '@/components/waste/NextCollections.vue'
  import CollectionHistory from '@/components/waste/CollectionHistory.vue'

  import { mapActions } from 'vuex'

  export default {
    name: 'Waste',
    components: {
      AccountSection,
      WasteOverview,
      WasteLatestBilling,
      WasteInformBox,
      WasteBillingHistory,
      NextCollections,
      CollectionHistory,
    },

    methods: {
      ...mapActions('waste', [
        'fetchNextCollections',
        'fetchLifts',
        'fetchTransactions',
        'fetchInvoicePdf',
      ]),
    },
    mounted() {
      this.fetchNextCollections()
      this.fetchLifts()
      this.fetchTransactions()
    },
  }
</script>

<style></style>
