import * as Msal from '@azure/msal-browser'

import { urls, clientIDs, authorities } from './authConfig'

const ENV = process.env.NODE_ENV

console.log(ENV)

class AuthService {
  constructor() {
    const msalConfig = {
      auth: {
        clientId: clientIDs[ENV],
        redirectUri: urls[ENV],
        authority: authorities[ENV].signIn,
        knownAuthorities: ['PandaGateway.b2clogin.com'],
        postLogoutRedirectUri: urls[ENV],
        navigateToLoginRequestUrl: true,
        requireAuthOnInitialize: true,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    }

    this.msalInstance = new Msal.PublicClientApplication(msalConfig)

    this.loginRequest = {
      scopes: [
        'openid',
        'offline_access',
        'https://PandaGateway.onmicrosoft.com/api/User',
      ],
    }

    this.idToken = ''
    this.accountId = ''

    this.account = null
  }

  async signIn() {
    try {
      const response = await this.msalInstance.handleRedirectPromise()
      if (response !== null) {
        this.account = this.msalInstance.getAllAccounts()[0]
        this.idToken = response.idToken
      } else {
        const currentAccounts = this.msalInstance.getAllAccounts()
        this.account = currentAccounts[0]

        if (!currentAccounts || currentAccounts.length < 1) {
          return this.msalInstance.loginRedirect(this.loginRequest)
        }
      }
    } catch (error) {
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
          this.msalInstance.loginRedirect({
            authority: authorities[ENV].resetPassword,
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  signOut() {
    const currentAccount = this.msalInstance.getAccountByHomeId(this.accountId)

    const logoutRequest = {
      account: currentAccount,
      authority: authorities[ENV].signIn,
    }
    // Replace this with logoutRedirect()
    this.msalInstance.logout(logoutRequest)
  }

  async getIdToken() {
    if (this.idToken !== '') {
      return this.idToken
    } else {
      return await this.refreshToken()
    }
  }

  async refreshToken() {
    const request = {
      scopes: [
        'https://PandaGateway.onmicrosoft.com/api/User',
        'openid',
        'profile',
      ],
      account: this.msalInstance.getAllAccounts()[0],
    }

    try {
      const tokenResponse = await this.msalInstance.acquireTokenSilent(request)
      this.idToken = tokenResponse.idToken
      return this.idToken
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return this.msalInstance.acquireTokenRedirect(request)
      }
    }
  }

  isAuthenticated() {
    const currentAccounts = this.msalInstance.getAllAccounts()
    return currentAccounts.length === 1
  }
}

export default new AuthService()
