<template>
  <div v-if="marketingOptions" class="conatiner mb-6 mt-4">
    <div class="row">
      <h6 class="mb-3 mt-3">Communication preferences</h6>
    </div>

    <div class="row">
      <div
        v-for="(option, index) in marketingOptions"
        :key="index"
        class="col-sm-12 mb-sm-1 col-md-6 col-lg-3"
      >
        <i
          class="fas"
          v-bind:class="{
            'fa-check': option.selected,
            'color-brand-primary': option.selected,
            'fa-times-circle': !option.selected,
          }"
        ></i>
        {{ option.itemDesc }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'CommunicationPreferences',
    computed: {
      ...mapGetters('power', ['marketingOptions']),
    },
  }
</script>

<style lang="scss" scoped></style>
