<template>
  <div class="overview position-relative hero-bg">
    <div class="bg-palegreen py-7">
      <div class="container">
        <div class="row">
          <div class="col-12 z-2 position-relative">
            <div
              class="d-flex align-items-center align-items-lg-end align-items-xxl-start mb-4"
            >
              <FlameIcon class="d-flex me-2" />
              <h1 class="h3 mb-0">
                Home Gas
              </h1>
            </div>
            <p class="font-size-larger mb-0 pb-0">
              {{ address }}
            </p>
            <p class="mt-1 mb-0">Account Number: {{ gasAccountNumber }}</p>
            <p class="mt-1 mb-5">GPRN: {{ gasAccountInfo.meterPointNumber }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h4>Account Summary</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4 d-grid">
            <div
              class="card shadow bg-radial-gradient-blue color-white p-3 rounded mb-4"
            >
              <div class="row">
                <div class="col">
                  <strong><h6 class="mb-0">Current Balance</h6></strong>
                  <p class="h3 m-0 p-0">€{{ gasBalance }}</p>
                  <p class="mt-0 mb-3">
                    Issued on <strong>{{ latestBillDate }}</strong>
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <strong><p class="my-0">Payment method</p></strong>
                  <div>{{ paymentOption }}</div>
                </div>
                <div class="col"></div>
              </div>
              <div class="row">
                <strong class="color-white">
                  <router-link
                    :to="{ name: 'gas', hash: '#makepayment' }"
                    tag="div"
                    class="text-decoration-none color-white d-inline-block cursor-pointer"
                    >Make a Payment
                    <i class="fas fa-chevron-right"></i></router-link
                ></strong>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 d-grid">
            <div class="card bg-white shadow p-3 rounded mb-4">
              <div class="row">
                <div class="col">
                  <strong><h6 class="mb-0">Latest bill</h6></strong>
                  <p class="h3 m-0">€{{ latestBillAmount }}</p>

                  <p class="mt-0 mb-3">
                    Is due on <strong>{{ latestBillDueDate }}</strong>
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <strong><p class="my-0">Previous bill</p></strong>
                  <div>€ {{ previousBillAmount }}</div>
                </div>
                <div class="col">
                  <strong><p class="my-0">Next bill date</p></strong>
                  <div>{{ nextBillDate }}</div>
                </div>
              </div>
              <div class="row">
                <strong>
                  <router-link
                    :to="{ name: 'gas-transaction-history' }"
                    class="color-grey-700 text-decoration-none d-block"
                    >View transaction history
                    <i class="fas fa-chevron-right"></i></router-link
                ></strong>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 d-grid">
            <div class="card bg-white shadow p-3 rounded mb-4">
              <div class="row">
                <div class="col">
                  <strong><h6 class="mb-0">Next meter read will be</h6></strong>
                  <p class="h3 m-0">{{ nexMeterRead }}</p>
                  <div class="mt-3">
                    <router-link
                      :to="{ name: 'gas', hash: '#submitreading' }"
                      class="bd-btn bd-btn-one mb-6 text-center mx-0"
                      >Submit Reading
                      <i class="fas fa-chevron-right"></i>
                    </router-link>
                  </div>
                  <!-- <div>How do I read my electricity meter?</div> -->
                </div>
              </div>

              <div class="row">
                <strong
                  ><router-link
                    :to="{ name: 'gas-reading-history' }"
                    class="color-grey-700 text-decoration-none d-block"
                    >View usage history
                    <i class="fas fa-chevron-right"></i></router-link
                ></strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import FlameIcon from '@/assets/images/flame.svg'
  export default {
    name: 'GasOverview',
    components: {
      FlameIcon,
    },

    computed: {
      ...mapGetters('power', ['fullName', 'address']),
      ...mapGetters('gas', [
        'gasAccountInfo',
        'gasBalance',
        'nexMeterRead',
        'latestBillAmount',
        'latestBillDate',
        'latestBillDueDate',
        'previousBillAmount',
        'paymentOption',
        'nextBillDate',
        'gasAccountNumber',
      ]),
    },
  }
</script>

<style lang="scss" scoped></style>
