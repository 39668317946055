import AuthService from '@/api/msalAuthService'
import request from '@/api/request.js'

const state = {
  confirmation: {
    text: {
      phoneNumber: '',
      textSent: false,
      validated: false,
      errorMessage: '',
    },
    email: {
      emailAddress: '',
      emailSent: false,
      validated: false,
      errorMessage: '',
    },
    pin: {
      validated: false,
      errorMessage: '',
    },
  },
  linkedAccounts: [],
  numberOfLinkedAccounts: null,
  newLinkedAccount: [],
  activeAccounts: {
    electricity: false,
    gas: false,
    waste: false,
  },
}

const getters = {
  linkedAccounts(state) {
    return state.linkedAccounts
  },

  activeAccounts(state) {
    return state.linkedAccounts.filter(account => account.isDefault)
  },

  inactiveAccounts(state) {
    return state.linkedAccounts.filter(account => !account.isDefault)
  },

  allAccounts(state, getters) {
    return state.linkedAccounts
  },

  confirmationPhoneNumber(state) {
    return state.confirmation.text.phoneNumber
  },

  confirmationEmail(state) {
    return state.confirmation.email.emailAddress
  },

  confirmationEmailStatus(state) {
    return state.confirmation.email.emailSent
  },

  confirmationTextStatus(state) {
    return state.confirmation.text.textSent
  },

  emailIsValidated(state) {
    return state.confirmation.email.validated
  },

  textIsValidated(state) {
    return state.confirmation.text.validated
  },

  pinIsValidated(state) {
    return state.confirmation.pin.validated
  },

  pinValidationError(state) {
    return state.confirmation.pin.errorMessage
  },

  isElectricityActive(state) {
    return state.activeAccounts.electricity
  },

  isGasActive(state) {
    return state.activeAccounts.gas
  },

  isWasteActive(state) {
    return state.activeAccounts.waste
  },
}
const actions = {
  /**
   * /api/link-account/link-initial-accounts:
   * This API should be called right after the user signup successfully in Azure B2C.
   * This Api will call the power API and domestic API passing the email and it'll create the accounts in the Gateway database.
   *
   * @param void
   */
  async linkInitialAccounts({ commit }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get(
        'link-account/link-initial-accounts'
      )
      const { apiHttpStatus, apiMessages: gatewayApiMessages } = response.data

      if (apiHttpStatus === 200) {
        // console.log('linkInitialAccounts', apiHttpStatus)
        commit('SET_INITIAL_ACCOUNTS')
      }
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * get linked accounts
   *
   * @param void
   */
  async fetchLinkedAccounts({ commit, rootState }) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get(
        'link-account/get-linked-accounts'
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: accounts,
      } = response.data

      if (gatewayHttpStatus === 200 && accounts.length > 0) {
        commit('SET_LINKED_ACCOUNTS', accounts)
        commit('SET_NUMBER_OF_ACCOUNT')

        // Account Types {​​​​​ Domestic = 1, Power = 2, Commercial = 3, Skips = 4 }
        accounts.forEach(account => {
          if (account.accountType === 1) {
            commit('linkAccount/SET_ACTIVE_ACCOUNT', 'waste', {
              root: true,
            })
          }
        })
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status)
      }
    }
  },

  /**
   * get linked accounts
   *
   * @param void
   */

  async toggleDefaultAccount({ commit, dispatch }, payload) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'link-account/toggle-default-account',
        {
          accountNumber: payload.account,
          accountType: payload.type,
        }
      )
      const {
        errors,
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: toggledSuccessfully,
      } = response.data

      if (toggledSuccessfully) {
        await dispatch('fetchLinkedAccounts')
        await dispatch('power/fetchCustomerData', null, { root: true })
        await dispatch('waste/fetchAccount', null, { root: true })
        await dispatch('power/fetchReadings', null, { root: true })
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status)
      }
    }
  },

  /**
   * /api/link-account/get-confirmation-code-by-text:
   *
   * This API should be called passing a mobile number.
   * The gateway will generate a 6-digit code and will send the code by SMS to the mobile number.
   *
   * @param string number "353857795003"
   */
  async getConfirmationCodeByText({ commit }, phoneNumber) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'link-account/get-confirmation-code-by-text',
        {
          mobileNumber: phoneNumber,
        }
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200 && data === true) {
        commit('SET_CONFIRMATION_TEXT_STATUS', true)
        commit('SET_CONFIRMATION_NUMBER', phoneNumber)
      }
    } catch (error) {
      console.log(error)
      commit('SET_CONFIRMATION_TEXT_STATUS', 'failed')
    }
  },

  /**
   * /api/link-account/get-confirmation-code-by-email:
   * This API should be called passing an email address.
   * The gateway will generate a 6-digit code and will send to the email address.
   *
   * @param {*} number
   */
  async getConfirmationCodeByEmail({ commit }, emailAddress) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'link-account/get-confirmation-code-by-email',
        {
          email: emailAddress,
        }
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      if (gatewayHttpStatus === 200 && data === true) {
        commit('SET_CONFIRMATION_EMAIL_STATUS', true)
        commit('SET_CONFIRMATION_EMAIL', emailAddress)
      }
    } catch (error) {
      commit('SET_CONFIRMATION_EMAIL_STATUS', 'failed')
      console.log(error)
    }
  },

  /**
   * Validate Confirmation Code
   *
   * /api/link-account/validate-confirmation-code
   *
   * This account should be called passing the sourceAccount(email or mobile number) and the confirmationCode.
   * This will check in the Gateway by the confirmation code + sourceAccount created maximum 30 minutes prior to this API call.
   * If the Gateway find the confirmation successfully, it'll mark it as Confirmed and it'll call the Panda Power
   * and Panda Domestic to get the accounts linked to the email or mobile number and will store those in the Gateway database.
   *
   *
   * @param string account (email or mobile number)
   * @param string code
   */
  async validateConfirmationCode({ commit, dispatch }, payload) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.post(
        'link-account/validate-confirmation-code',
        {
          sourceAccount: payload.sourceAccount,
          confirmationCode: payload.code,
        }
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data: accounts,
      } = response.data

      /*
      {
        "apiHttpStatus": 200,
        "data": [
          {
            "account": "1000016",
            "accountType": 1,
            "isDefault": true
          }
        ],
        "apiMessages": []
      }
      */
      console.log(response.data)
      console.log(gatewayHttpStatus)
      console.log(gatewayApiMessages)

      if (gatewayHttpStatus === 200) {
        accounts.forEach(async theAccount => {
          const { account, accountType } = theAccount

          commit('SET_NEW_LINKED_ACCOUNT', theAccount)

          await dispatch('toggleDefaultAccount', {
            account: account,
            type: accountType,
          })
        })

        if (payload.sourceAccount.includes('@')) {
          commit('SET_EMAIL_VALIDATED')
        } else {
          commit('SET_TEXT_VALIDATED')
        }
      }
    } catch (error) {
      /*
        apiHttpStatus: 200
        apiMessages: Array []
        errors: Array [ "Your confirmation code was not found or it was expired. Request a new confirmation code and try again." ]
​​      */
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        errors,
      } = error.response.data

      console.log(errors)
    }
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   *
   * /api/domestic/LinkAccount/{accountNumber}/verify-pin/{pin}
   */
  async verifyPin({ commit, dispatch }, payload) {
    try {
      const token = await AuthService.getIdToken()
      const axiosInstance = request.setBearer(token)
      const response = await axiosInstance.get(
        `domestic/LinkAccount/${payload.accountNumber}/verify-pin/${payload.pin}`
      )

      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        data,
      } = response.data

      /*
      {
        "apiHttpStatus": 200,
        "data": "Successfully linked Domestic account",
        "apiMessages": []
      }
      */

      console.log(gatewayHttpStatus)
      console.log(data)

      if (
        gatewayHttpStatus === 200 &&
        data === 'Successfully linked Domestic account'
      ) {
        commit('SET_PIN_VALIDATION_STATUS', true)
        commit('SET_PIN_VALIDATION_ERROR_MESSAGE', '')

        await dispatch('toggleDefaultAccount', {
          account: payload.accountNumber,
          type: 1,
        })

        await dispatch('waste/fetchAccount', null, { root: true })
      } else if (gatewayHttpStatus === 401) {
        commit('SET_PIN_VALIDATION_STATUS', false)
        commit('SET_PIN_VALIDATION_ERROR_MESSAGE', data)
      }
    } catch (error) {
      const {
        apiHttpStatus: gatewayHttpStatus,
        apiMessages: gatewayApiMessages,
        errors: errorMessages,
      } = error.response.data

      if (gatewayHttpStatus === 200) {
        commit('SET_PIN_VALIDATION_ERROR_MESSAGE', errorMessages[0])
      }
    }
  },
}
const mutations = {
  SET_CONFIRMATION_EMAIL(state, email) {
    state.confirmation.email.emailAddress = email
  },

  SET_CONFIRMATION_EMAIL_STATUS(state, status) {
    state.confirmation.email.emailSent = true
  },

  SET_EMAIL_VALIDATED(state) {
    state.confirmation.email.validated = true
  },

  DELETE_EMAIL_CONFORMATION_DETAILS(state) {
    state.confirmation.email.emailAddress = ''
    state.confirmation.email.emailSent = false
    state.confirmation.email.validated = false
  },

  SET_CONFIRMATION_NUMBER(state, number) {
    state.confirmation.text.phoneNumber = number
  },

  SET_CONFIRMATION_TEXT_STATUS(state, status) {
    state.confirmation.text.textSent = true
  },

  SET_TEXT_VALIDATED(state) {
    state.confirmation.text.validated = true
  },

  DELETE_TEXT_CONFORMATION_DETAILS(state) {
    state.confirmation.text.phoneNumber = ''
    state.confirmation.text.textSent = false
    state.confirmation.text.validated = false
  },

  SET_NEW_LINKED_ACCOUNT(state, account) {
    state.newLinkedAccount.push(account)
  },

  SET_INITIAL_ACCOUNTS(state) {
    state.linkedInitialAccounts = true
  },

  SET_PIN_VALIDATION_STATUS(state, status) {
    state.confirmation.pin.validated = status
  },

  SET_PIN_VALIDATION_ERROR_MESSAGE(state, message) {
    if (message === 'Problem to link your domestic account') {
      state.confirmation.pin.errorMessage =
        'Problem to link your domestic account, please check your account number and pin.'
    }
  },

  SET_LINKED_ACCOUNTS(state, payload) {
    const accountTypes = {
      1: 'Domestic',
      2: 'Power',
      3: 'Commercial',
      4: 'Skips',
    }

    const accounts = payload.map(account => {
      account.accountTypeName = accountTypes[account.accountType]
      account.accountNumber = account.account
      return account
    })

    state.linkedAccounts = [...accounts]
  },

  SET_ACTIVE_ACCOUNT(state, accountType) {
    state.activeAccounts[accountType] = true
  },

  SET_NUMBER_OF_ACCOUNT(state) {
    state.numberOfLinkedAccounts = state.linkedAccounts.length
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
