<template>
  <div>
    <form @submit.prevent="getConfirmationCode">
      <h1 class="h6">Account verification</h1>
      <p class="">
        Please enter the mobile phone number associated with your account and
        we’ll send you a SMS message with an authentication code for us to
        validate and retrieve your account.
      </p>

      <label v-if="errorMessage.length" for="prefix">{{ errorMessage }}</label>
      <label v-else for="prefix">Mobile Number</label>
      <div class="d-flex justify-content-start">
        <div class="col-2 col-sm-3">
          <select
            v-model="prefix"
            id="prefix"
            name="prefix"
            class="prefix-select"
            required
          >
            <option value="">prefix</option>
            <option value="83">083</option>
            <option value="85">085</option>
            <option value="86">086</option>
            <option value="87">087</option>
            <option value="89">089</option>
          </select>
        </div>
        <div class="col-10">
          <TheCodeInput
            :loading="false"
            class="mobile-number-code"
            :fields="7"
            fieldWidth="45px"
            v-on:change="onChange"
            v-on:complete="onComplete"
          />
        </div>
      </div>

      <p class="font-size-small">
        By providing your mobile number, you agree to receive text messages from
        Panda.
      </p>
      <input
        @click="goBack"
        type="button"
        value="Back"
        class="bd-btn bd-btn-two me-3 mb-3"
      />
      <input
        @submit="getConfirmationCode"
        type="submit"
        value="Send Authentication Code"
        class="bd-btn bd-btn-one mb-md-4"
      />

      <div>
        <p>
          Need assistance? Contact
          <a href="https://www.panda.ie/help/">Support</a> now.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
  import ConfirmationScreen from '@/components/layouts/ConfirmationScreen.vue'
  import TheCodeInput from '@/components/TheCodeInput.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      TheCodeInput,
      ConfirmationScreen,
    },

    data() {
      return {
        mobileNumber: '',
        prefix: '',
        phone: '',
        isPhoneNumberValid: false,
        errorMessage: '',
      }
    },

    methods: {
      ...mapActions('linkAccount', ['getConfirmationCodeByText']),

      onChange(key) {
        // console.log('onChange ', key)
      },
      onComplete(number) {
        // console.log('onComplete', number)
        this.phone = number
      },
      goBack() {
        this.$router.push({ name: 'link-account' })
      },

      validatePhoneNumber() {
        if (
          this.prefix !== '' &&
          this.phone !== '' &&
          this.phone.length === 7
        ) {
          this.isPhoneNumberValid = true
          this.errorMessage = ''
        } else {
          this.isPhoneNumberValid = false
          this.errorMessage = 'Please, enter a valid mobile number!'
        }
      },

      async getConfirmationCode() {
        this.validatePhoneNumber()

        if (this.isPhoneNumberValid) {
          this.mobileNumber = `353${this.prefix}${this.phone}`

          await this.getConfirmationCodeByText(this.mobileNumber)

          if (this.confirmationTextStatus) {
            this.$router.push({ name: 'validate-text-code' })
          }
        }
      },
    },

    computed: {
      ...mapGetters('linkAccount', ['confirmationTextStatus']),
    },
  }
</script>

<style lang="scss">
  .prefix-select {
    background: transparent;
    // margin-right: 1rem;
    // width: 50px;
    text-align: center;
    padding: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    height: 54px;
    box-sizing: border-box;
    font-size: 1.125rem;
  }
  .mobile-number-code > .the-code-input > input {
    border: solid 1px #292929;
    border-right: none;
    font-family: inherit;
    font-size: 1.125rem;
    color: #525461;
    text-align: center;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: initial;
  }
  .mobile-number-code > .the-code-input > input:first-child {
    // border-top-left-radius: 6px;
    // border-bottom-left-radius: 6px;
  }

  .mobile-number-code > .the-code-input > input:last-child {
    border-right: solid 1px #292929;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .mobile-number-code > .the-code-input > input:focus {
    outline: none;
    border: 2px solid #41bb2e;
    caret-color: #41bb2e;
  }

  .mobile-number-code > .the-code-input > input:focus + input {
    border-left: none;
  }
</style>
