<template>
  <ConfirmationScreen>
    <GetConfirmationCodeByText />
  </ConfirmationScreen>
</template>

<script>
  import ConfirmationScreen from '@/components/layouts/ConfirmationScreen.vue'
  import GetConfirmationCodeByText from '@/components/LinkAccount/GetConfirmationCodeByText.vue'
  export default {
    components: {
      ConfirmationScreen,
      GetConfirmationCodeByText,
    },
  }
</script>
<style lang="scss" scoped></style>
