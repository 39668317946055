<template>
  <div>
    <div v-if="emailIsValidated || textIsValidated">
      <h1 class="h6 mb-2">Account verified</h1>
      <p class="mb-4">Thank You, Your account was successfully added!</p>

      <button @click="cancel" class="bd-btn bd-btn-one">
        View Your Accounts
      </button>
    </div>
    <div v-else>
      <TheSmallLoader :showLoader="isLoading" />
      <h1 class="h6 mb-2">Account verification</h1>
      <p v-if="verificationType === 'text'">
        To continue, please enter the 6-digit verification code sent to your
        mobile phone ending in {{ getLast4digits }}.
      </p>
      <p v-if="verificationType === 'email'">
        To continue, please enter the 6-digit verification code sent to the
        email address you provided.
      </p>
      <p v-if="showError">
        <strong>{{ theErrorMessage }}</strong>
      </p>
      <label for="">Authentication Code</label>
      <TheCodeInput
        :loading="false"
        :autoFocus="true"
        class="authentication-code"
        v-on:complete="onComplete"
      />
      <p class="mt-2 mb-4">
        Didn’t receive a code?
        <a href="#" @click.prevent="resendVerificationCode">Resend code.</a>
      </p>
      <input @click="cancel" type="button" value="cancel" class="bd-btn me-2" />
      <input
        @click="validateCode"
        type="button"
        value="Verify Account"
        class="bd-btn bd-btn-one"
      />
      <p class="mt-3">
        <router-link :to="{ name: 'link-account' }">
          Add the account another way
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import TheCodeInput from '@/components/TheCodeInput.vue'
  import TheSmallLoader from '@/components/TheSmallLoader.vue'

  export default {
    components: {
      TheCodeInput,
      TheSmallLoader,
    },

    data() {
      return {
        code: '',
        verificationType: '',
        isLoading: false,
        showError: false,
        theErrorMessage:
          'Your confirmation code was not found or it was expired. Request a new confirmation code and try again.',
      }
    },

    methods: {
      ...mapActions('linkAccount', [
        'validateConfirmationCode',
        'getConfirmationCodeByEmail',
        'getConfirmationCodeByText',
      ]),

      ...mapMutations('linkAccount', [
        'DELETE_EMAIL_CONFORMATION_DETAILS',
        'DELETE_TEXT_CONFORMATION_DETAILS',
      ]),

      async validateCode() {
        let sourceAccount = ''

        if (this.verificationType === 'email') {
          sourceAccount = this.confirmationEmail
        } else if (this.verificationType === 'text') {
          sourceAccount = this.confirmationPhoneNumber
        }

        if (this.code) {
          this.isLoading = true
          await this.validateConfirmationCode({
            sourceAccount: sourceAccount,
            code: this.code,
          })
          this.isLoading = false

          if (!this.emailIsValidated || !this.textIsValidated) {
            this.showError = true
          }
        }
      },

      setVerificationType() {
        if (this.$route.name === 'validate-email-code') {
          this.verificationType = 'email'
        } else if (this.$route.name === 'validate-text-code') {
          this.verificationType = 'text'
        }
      },

      async resendVerificationCode() {
        if (this.verificationType === 'email' && this.confirmationEmail) {
          if (window.confirm('Resend verification code?')) {
            await this.getConfirmationCodeByEmail(this.confirmationEmail)
          }
        }

        if (this.verificationType === 'text' && this.confirmationPhoneNumber) {
          if (window.confirm('Resend verification code?')) {
            await this.getConfirmationCodeByText(this.confirmationPhoneNumber)
          }
        }
      },

      /**
       * Redirect the user if email or phone number not found
       */
      redirectIfNoSourceAccount() {
        if (this.verificationType === 'email' && !this.confirmationEmail) {
          this.$router.replace({ name: 'link-account-by-email' })
        }
        if (this.verificationType === 'text' && !this.confirmationPhoneNumber) {
          this.$router.replace({ name: 'link-account-by-text' })
        }
      },

      onComplete(code) {
        this.code = code
      },

      cancel() {
        if (this.verificationType === 'email') {
          this.DELETE_EMAIL_CONFORMATION_DETAILS()
        } else if (this.verificationType === 'text') {
          this.DELETE_TEXT_CONFORMATION_DETAILS()
        }

        this.$router.replace({ name: 'home' })
      },

      preventRefresh(event) {
        if (!this.emailIsValidated || !this.textIsValidated) {
          event.preventDefault()
          // Chrome requires returnValue to be set.
          event.returnValue = ''
        }
      },
    },

    computed: {
      ...mapGetters('linkAccount', [
        'confirmationPhoneNumber',
        'confirmationEmail',
        'emailIsValidated',
        'textIsValidated',
      ]),

      currentRouteName() {
        return this.$route.name
      },

      getLast4digits() {
        if (this.confirmationPhoneNumber) {
          return this.confirmationPhoneNumber.slice(
            this.confirmationPhoneNumber.length - 4
          )
        }
      },
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventRefresh)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('beforeunload', this.preventRefresh)
      })
    },

    mounted() {
      this.setVerificationType()
      this.redirectIfNoSourceAccount()
    },
  }
</script>

<style lang="scss">
  .authentication-code > .the-code-input > input {
    border: solid 1px #292929;
    border-right: none;
    font-family: inherit;
    font-size: 20px;
    color: #525461;
    text-align: center;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: initial;
  }

  .authentication-code > .the-code-input > input:last-child {
    border-right: solid 1px #292929;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .authentication-code > .the-code-input > input:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .authentication-code > .the-code-input > input:focus {
    outline: none;
    border: 2px solid #41bb2e;
    caret-color: #41bb2e;
  }

  .authentication-code > .the-code-input > input:focus + input {
    border-left: none;
  }
</style>
