<template>
  <div
    v-if="show"
    class="w-100 d-flex align-items-center d-flex justify-content-center text-center color-white bg-linear-gradient-green
    rounded p-4 p-lg-4 pb-lg-4"
    id="api-message"
  >
    <strong>{{ messageText }}</strong>
  </div>
</template>

<script>
  export default {
    name: 'ApiMessage',
    props: {
      show: {
        type: Boolean,
      },
      messageText: {
        type: String,
      },
    },
  }
</script>

<style lang="scss">
  #api-message {
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: fadeIn 300ms linear;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
