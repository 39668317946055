<template>
  <ConfirmationScreen>
    <GetConfirmationCodeByEmail />
  </ConfirmationScreen>
</template>

<script>
  import ConfirmationScreen from '@/components/layouts/ConfirmationScreen.vue'
  import GetConfirmationCodeByEmail from '@/components/LinkAccount/GetConfirmationCodeByEmail.vue'
  export default {
    components: {
      ConfirmationScreen,
      GetConfirmationCodeByEmail,
    },
  }
</script>
<style lang="scss" scoped></style>
