<template>
  <div>
    <ElectricityOverview />

    <AccountSection sectionTitle="Billing History">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-7 p-0 p-md-2">
          <ElectricityLatestBilling />
          <ElectricityBillingHistory
            title="Billing history"
            buttonText="View transaction history"
          />
        </div>
        <div class="col-12 col-md-12 col-lg-5 p-0 p-md-2">
          <!-- <ElectricityInformBox /> -->
          <TheInfoBox
            heading="Want fixed monthly bills?"
            body="Manage your
          monthly outgoings with our Level Pay plan. We'll monitor your energy
          usage and suggest a monthly payment that will spread your costs out
          over the year"
            linkText="Switch to level pay"
            link="https://www.panda.ie/home/contact/"
          />

          <a
            href="https://www.panda.ie/panda-home/skip-bag-hire/"
            target="_blank"
          >
            <div class="mt-5">
              <img src="@/assets/images/cta-skips.png" />
            </div>
          </a>
        </div>
      </div>
    </AccountSection>

    <!-- NOT Smart Meter -->
    <div v-if="!isSmartMeter">
      <AccountSection sectionTitle="Submit meter reading">
        <div class="row">
          <MeterInfo TransactionHistory="/electricity/transaction-history" />
          <div class="col-12 col-md-12 col-lg-7">
            <ElectricitySubmitReading />
          </div>
          <div class="col-12 col-md-12 col-lg-5 mt-3">
            <TheInfoBox
              heading="How do I read my meter?"
              body="Make a note of the figures from left to right, ignoring the red numbers or if they have a red box surrounding it. Meter readings help keep your bill accurate. ESB Networks or Gas Networks Ireland will attempt to read your meters four times a year. Need more help?"
              linkText="Visit our help centre"
              link="https://www.panda.ie/faq-category/meter-readings/#faqs"
            />
          </div>
          <hr class="my-7" />
          <!-- Meter reading history -->
          <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
            <ElectricityMeterReadingHistory
              TableTitle="Meter reading history"
              buttonText=""
              TableHeadingOne="Date"
              TableHeadingTwo="Meter reading"
              TableHeadingThree="Reading type"
            />
          </div>
          <!-- Average usage chart -->
          <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
            <ElectricityAverageUsageChart />
            <!-- @todo: Move LineChart into ElectricityAverageUsageChart -->
            <LineChart />
          </div>
        </div>
      </AccountSection>
    </div>

    <!-- Smart Meter -->
    <div v-if="isSmartMeter">
      <AccountSection sectionTitle="Meter reading">
        <div class="row">
          <MeterInfo TransactionHistory="/electricity/transaction-history" />
          <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
            <SmartMeterReadingHistory
              TableTitle="Usage history"
              buttonText=""
              TableHeadingOne="Date"
              TableHeadingTwo="Average hourly Kw"
              TableHeadingThree="Total Kw"
            />
          </div>
          <div class="col-12 col-md-12 col-lg-6 p-0 p-md-2">
            <SmartUsageChart />
          </div>
        </div>
      </AccountSection>
    </div>

    <PowerAccountDetails
      sectionTitle="Account details"
      :accountName="fullName"
      :accountEmail="accountDetails.emailAddress"
      :accountPhone="accountDetails.phoneNo1"
      :accountHPhone="accountDetails.phoneNo2"
      :accountNumber="electricityAccountNumber"
      :accountMPRN="electricityAccountInfo.meterPointNumber"
      :accountGPRN="false"
      :accountAddress="address"
    />
  </div>
</template>

<script>
  import ElectricityOverview from '@/components/electricity/Overview.vue'
  import ElectricityBillingHistory from '@/components/electricity/BillingHistory.vue'

  import ElectricityLatestBilling from '@/components/electricity/LatestBilling.vue'
  import ElectricitySubmitReading from '@/components/electricity/SubmitReading.vue'
  import TheInfoBox from '@/components/TheInfoBox.vue'

  import ElectricityMeterReadingHistory from '@/components/electricity/MeterReadingHistory.vue'
  import SmartMeterReadingHistory from '@/components/electricity/SmartMeterReadingHistory.vue'
  import ElectricityAverageUsageChart from '@/components/electricity/AverageUsageChart.vue'

  import AccountSection from '@/components/layouts/AccountSection.vue'
  import MeterInfo from '@/components/electricity/MeterInfo.vue'
  import LineChart from '@/components/electricity/LineChart.vue'
  import SmartUsageChart from '@/components/electricity/SmartUsageChart.vue'

  import PowerAccountDetails from '@/components/PowerAccountDetails.vue'

  import { mapGetters } from 'vuex'
  export default {
    name: 'Electricity',
    components: {
      AccountSection,
      ElectricityOverview,
      ElectricityBillingHistory,
      ElectricityLatestBilling,
      ElectricitySubmitReading,
      ElectricityMeterReadingHistory,
      ElectricityAverageUsageChart,
      LineChart,
      MeterInfo,
      PowerAccountDetails,
      SmartMeterReadingHistory,
      SmartUsageChart,
      TheInfoBox,
    },

    computed: {
      ...mapGetters('power', ['accountDetails', 'fullName', 'address']),
      ...mapGetters('electricity', [
        'electricityAccountInfo',
        'electricityAccountNumber',
        'isSmartMeter',
      ]),
    },
  }
</script>

<style></style>
