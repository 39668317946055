<template>
  <div
    v-if="electricityAccountNumber || gasAccountNumber || wasteAccountNumber"
    class=""
  >
    <div class="row">
      <h6 class="mb-4 mt-0">Plan details</h6>
    </div>
    <div class="row">
      <strong><p>Your Plan</p></strong>
      <div v-if="electricityAccountNumber" class="col-12 col-md-12 col-lg-3">
        <div class="row">
          <div class="col-2 col-md-1 col-lg-2" style="margin-right:16px;">
            <BulbIcon />
          </div>
          <div class="col-9">
            <h4 class="mb-0">Home Electricity</h4>
            <p class="mt-0">Account no: {{ electricityAccountNumber }}</p>
          </div>
        </div>
      </div>
      <div v-if="gasAccountNumber" class="col-12 col-md-12 col-lg-3">
        <div class="row">
          <div class="col-2 col-md-1 col-lg-2" style="margin-right:16px;">
            <FlameIcon />
          </div>
          <div class="col-9">
            <h4 class="mb-0">Home Gas</h4>
            <p class="mt-0">Account no: {{ gasAccountNumber }}</p>
          </div>
        </div>
      </div>
      <div v-if="wasteAccountNumber" class="col-12 col-md-12 col-lg-6">
        <div class="row">
          <div class="col-2 col-md-1" style="margin-right:16px;">
            <DumpsterIcon />
          </div>
          <div class="col-9">
            <h4 class="mb-0">Home Recycling</h4>
            <p class="mt-0">Account no: {{ wasteAccountNumber }}</p>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BulbIcon from '@/assets/images/bulb.svg'
  import DumpsterIcon from '@/assets/images/dumpster.svg'
  import FlameIcon from '@/assets/images/flame.svg'

  export default {
    components: {
      BulbIcon,
      DumpsterIcon,
      FlameIcon,
    },
    computed: {
      ...mapGetters('electricity', ['electricityAccountNumber']),
      ...mapGetters('gas', ['gasAccountNumber']),
      ...mapGetters('waste', ['wasteAccountNumber']),
    },
  }
</script>

<style lang="scss" scoped></style>
