<template>
  <div>
    <div class="row ">
      <div class="col-4">
        <h6>{{ title }}</h6>
      </div>
      <div class="col-8">
        <router-link
          :to="{ name: 'gas-transaction-history' }"
          class="color-grey-700 text-decoration-none text-right d-block mb-3"
          >{{ buttonText }}</router-link
        >
      </div>
    </div>

    <div
      v-for="(trasaction, index) in trasactions"
      :key="index"
      class="trasactions"
    >
      <div class="bg-grey rounded px-3 py-2 mb-2">
        <div class="row">
          <template v-if="trasaction.type === 'payment'">
            <div class="col-8">
              <i class="fas fa-euro-sign"></i><strong>Payment</strong> -
              {{ trasaction.formattedDate }}
            </div>
            <div class="col-4 text-right">- €{{ trasaction.amount }}</div>
          </template>

          <template v-else-if="trasaction.type === 'invoice'">
            <div class="col-8">
              <i class="fas fa-file"></i><strong>Invoice</strong> -
              {{ trasaction.formattedDate }}
            </div>
            <div class="col-4 text-right">
              <a
                @click="openInvoice(trasaction.number, $event)"
                href="#"
                class="text-decoration-none"
                >Download <i class="fas fa-arrow-alt-to-bottom"></i
              ></a>
            </div>
          </template>
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    data() {
      return {
        trasactions: [],
      }
    },
    props: {
      title: {
        type: String,
      },
      buttonText: {
        type: String,
      },
      type: {
        type: String,
      },
    },
    methods: {
      ...mapActions('power', ['fetchInvoiceData']),

      asignTransactions() {
        if (this.$props.type === 'payments') {
          this.trasactions = this.gasPayments
        } else if (this.$props.type === 'invoices') {
          this.trasactions = this.gasInvoices
        } else {
          this.trasactions = this.gasBillingHistorySixItems
        }
      },

      saveAs(uri, filename) {
        const link = document.createElement('a')
        if (typeof link.download === 'string') {
          link.href = uri
          link.download = filename
          //Firefox requires the link to be in the body
          document.body.appendChild(link)
          //simulate click
          link.click()
          //remove the link when done
          document.body.removeChild(link)
        } else {
          window.open(uri)
        }
      },

      async openInvoice(invoiceNumber, event) {
        event.preventDefault()
        const invoceData = await this.fetchInvoiceData(invoiceNumber)
        const content = 'data:application/pdf;base64, ' + invoceData.invoiceFile
        this.saveAs(content, invoceData.invoiceFileName)
      },
    },
    computed: {
      ...mapGetters('gas', [
        'gasBillingHistorySixItems',
        'gasPayments',
        'gasInvoices',
      ]),
    },
    created() {
      this.asignTransactions()
    },

    watch: {
      gasPayments: {
        handler(status) {
          this.asignTransactions()
        },
        deep: true,
      },
    },
  }
</script>

<style lang="scss" scoped></style>
