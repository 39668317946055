import axios from 'axios'

const baseUrls = {
  development: 'https://pandagateway-staging.azurewebsites.net/api',
  staging: 'https://pandagateway-staging.azurewebsites.net/api',
  production: 'https://pandagateway.azurewebsites.net/api',
}

const baseURL = baseUrls[process.env.NODE_ENV]

export default {
  baseURL,
  setBearer(token) {
    return axios.create({
      baseURL: baseURL,
      timeout: 30000,
      crossDomain: true,
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
    })
  },
}
