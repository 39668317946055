<template>
  <div>
    <div class="bg-grey rounded p-4 p-lg-4 pb-lg-4">
      <h4 class="">{{ heading }}</h4>
      <p class="pb-4">
        {{ body }}
      </p>
      <a
        class="color-aquamarine hover-color-aquamarine text-decoration-none  heading-font-family d-inline-flex align-items-center"
        :href="link"
        target="_blank"
        >{{ linkText }}</a
      >

      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TheInfoBox',
    props: ['heading', 'body', 'linkText', 'link'],
  }
</script>

<style lang="scss" scoped></style>
